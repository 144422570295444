@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';
.tooltip {
    position: relative;
    margin-left: 8px;
    &:hover .tooltip__alt {
        display: flex;
    }

    &__icon {
        width: 14px;
        height: 14px;
        background: rgba(67, 88, 117, 0.08);
        border-radius: 50%;
        color: #435875;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: size(10px);
        cursor: pointer;
        padding: 0;
        span {
            font-size: size(10px);
        }
    }

    &__alt {
        position: absolute;
        max-width: 250px;
        width: max-content;
        left: size(7px);
        bottom: size(24px);
        z-index: 1000;
        display: none;
        text-transform: initial;

        span {
            padding: 6px 8px;
            background: #EFF0F9;
            font-size: size(12px);
            border-radius: size(4px) size(4px) size(4px) 0;
            display: flex;
            align-items: center;
            position: relative;
            font-weight: 400;
            line-height: 12px;
            color: #435875;
            width: 100%;

            pre {
                font-family: $ff-inter;
                font-size: size(12px);
                margin: 0;
                width: 100%;
                white-space: break-spaces;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: size(-5px);
                left: 0;
                display: flex;
                width: size(6px);
                height: size(5px);
                background: #EFF0F9;
                clip-path: polygon(0 0, 0 100%, 100% 0);
                border-radius: 0 0 0 size(3px);
            }
        }
    }

    &--color-blue {
        .tooltip__icon {
            color: $color-dodger-blue;
            background: transparent;
            border: size(1px) solid $color-dodger-blue;
        }
    }

    &.bottom {
        .tooltip__alt {
            bottom: auto;
            top: size(24px);

            span {
                border-radius: 0 size(4px) size(4px) size(4px);
                white-space: pre-wrap;
                &:after {
                    top: size(-5px);
                    bottom: auto;
                    clip-path: polygon(0 100%, 100% 100%, 0 0);
                    border-radius: size(3px) 0 0 0;
                }
            }
        }
        &.left {
            .tooltip__alt {
                bottom: auto;
                top: size(24px);
                right: 0;
                left: auto;

                span {
                    border-radius: 0 size(4px) size(4px) size(4px);
                    white-space: pre-wrap;
                    &:after {
                        top: size(-5px);
                        bottom: auto;
                        clip-path: polygon(0 100%, 100% 100%, 0 0);
                        border-radius: size(3px) 0 0 0;
                    }
                }
            }
        }
    }
    &--right {
        .tooltip__alt {
            right: 0;
            left: auto;

            span {
                border-radius: size(4px) size(4px) 0 size(4px);
                white-space: pre-wrap;

                &:after {
                    right: 0;
                    left: auto;
                }
            }
        }
    }
}