@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.access-time{
  &__block {
    margin-bottom: 30px;
    & .custom-input {
      margin: 0;
      & svg {
        top: auto;
        bottom: 0;
      }
      & input {
        height: 41px;
      }
    }
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
      width: 49%;
    }
  }
}

.event-type {
  margin-bottom: 30px;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #435875;
    margin-bottom: 12px;
    display: flex;
  }
  &__block {
    display: flex;
    align-items: center;
  }
  &__item {
    border: 1px solid rgba(67, 88, 117, 0.08);
    border-radius: 10px;
    background: transparent;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      border: 1px solid #007BFF;
    }
    img {
      width: 22px;
      margin: 0;
      padding: 0;
    }
  }
}