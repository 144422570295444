@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.page-content {
  &__nav {
    &.active {
      width: size(200px);
      max-width: size(200px);

      .side-menu {
        max-width: size(200px);

        .side-menu__title {
          display: flex;
        }
        .side-menu__btn {
          .btn__text {
            opacity: 1;
          }
        }
      }
    }
  }
}

.side-menu {
  max-width: size(90px);
  padding: 0 size(20px);
  transition: width ease-out 100ms;

  &__list {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    max-height: size(50px);
    position: relative;
    display: flex;
    align-items: center;

    .btn--icon:not(:hover) {
      background-color: $color-transparent;
    }

    svg {
      min-width: size(20px);
    }

    &.is-active {
      //pointer-events: none;

      .btn--icon {
        background-color: $color-alice-blue;
      }
      &:hover {
        .side-menu__alt {
          display: block;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: size(13px);
    }
    &:hover {
      .side-menu__alt {
        display: block;
      }
    }
  }

  &__btn {
    justify-content: flex-start;
    .btn__text {
      transition: opacity ease-out 200ms;
      width: auto;
      height: auto;
      position: static;
      font-size: size(10px);
      color: #435875;
      margin: 0 0 0 size(6px);
      opacity: 0;
      clip-path: none;
    }
  }

  &__title {
    display: none;
    font-size: size(10px);
    white-space: nowrap;
  }

  &__alt {
    position: absolute;
    height: size(30px);
    width: max-content;
    left: size(25px);
    top: size(-25px);
    z-index: 1000;
    display: none;

    span {
      padding: 0 size(8px);
      background: #435875;
      font-size: size(10px);
      border-radius: size(4px) size(4px) size(4px) 0;
      color: #fff;
      display: flex;
      align-items: center;
      height: size(24px);
      width: max-content;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: size(24px);
        left: 0;
        display: flex;
        width: size(6px);
        height: size(5px);
        background: #435875;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        border-radius: 0 0 0 size(3px);
      }
    }
  }
  &-sub {
    position: absolute;
    top: 0;
    left: size(50px);
    border: size(1px) solid #43587514;
    z-index: 99;
    background: #FDFDFF;
    border-radius: size(10px);
    box-shadow: 0 74px 44px 0 #00000005;
    padding: size(12px);

    &__btn {
      white-space: nowrap;
      color: #435875;
      justify-content: flex-start;
      padding: size(8px) size(12px);
      min-height: size(33px);
      margin-bottom: size(4px);
      border-radius: size(6px);
      &:last-child {
        margin-bottom: 0;
      }
      .btn__text {
        font-size: size(14px);
      }
    }
  }
}
//box-shadow: 0px 0px 0px 0px #00000008;
//box-shadow: 0px 8px 18px 0px #00000008;
//box-shadow: 0px 33px 33px 0px #00000008;
//box-shadow: 0px 74px 44px 0px #00000005;
