.notification {
  display: flex;
  position: absolute;
  padding: 2px 30px;
  background: #FF990050;
  width: 100%;

  height: 21px;
  align-items: center;
  justify-content: center;
  span {
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
}