@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.dashboard-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: size(27px) size(32px) size(29px);
  background-color: $color-default-white;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  font-weight: 500;

  &__unit {
    display: inline-flex;
    margin: 0 0 size(6px);
    font-size: size(32px);
    line-height: size(39px);
  }

  &__description {
    margin: 0;
    color: $color-rock-blue;
  }
}
