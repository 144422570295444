.OnBoard {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.logo {
  width: 155px;
  height: 40px;
  flex-shrink: 0;
  margin-right: auto;
}

.main_part {
  background: #f9faff;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
  background: #f9f9ff;
  border-width: 0 2px 0 0;
  border-color: rgba(239, 240, 249, 1);
  border-style: solid;
  padding-top: 112px;
  margin: 0 60px 0 0;
  max-height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.link_item {
  display: flex;
  width: 320px;
  height: 60px;
  align-items: center;
  //justify-content: center;
  font-size: 18px;
}

.link {
  display: flex;
  color: #798EA6;
  height: 32px;
  align-items: center;
  min-width: 319px;
  min-height: 60px;
  padding-left: 12px;
}

.rocket_icon {
  margin-top: auto;
  margin-bottom: 0;
}

.step_number {
  display: flex;
  background: #ECF3FD;
  margin-right: 10px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  color: #007DFF;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.step_done {
  pointer-events: none;
}

.step_number_done {
  display: flex;
  background: #00D284;
  margin-right: 10px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.link_selected {
  color: #007DFF;
  background: #ECF3FD;
  border-radius: 12px;
  > div {
    background: #007DFF;
    color: #FFF;
  }
}

.link_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-right: auto;
}

.close_btn {
  color: rgba(125, 141, 164, 1);
  font-size: 16px;
  padding: 0;
  margin: 20px 0 50px 0;
  cursor: pointer;
}
