@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.docs {
  &__wrapper {
    p {
      font-size: size(14px);
      button {
        font-size: size(14px);
        background: transparent;
        color: $color-dodger-blue;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover {
          opacity: .7;
        }
      }
      a {
        font-size: size(14px);
        cursor: pointer;
        color: $color-dodger-blue;
        &:hover {
          text-decoration:underline;
        }
      }
    }
  }
  &__head {
    margin: 0;
    padding: 0;
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: $color-dodger-blue;
      text-align: left;
      &:hover {
        opacity: .8;
      }
    }
  }
  &__table {
    border: 2px solid #EFF0F9;
    border-radius: 10px;
    overflow-x: auto;
    max-width: 100%;
    table {
      width: 100%;
      min-width: 1000px;
      //padding: 10px 5px;
      border-collapse: separate;
      border-spacing: 0;
      //table-layout: fixed;

      thead {
        background: #EFF0F9;

        tr th {
          border: none;
          text-align: left;
          padding: 10px 15px;
          font-size: size(14px);
          color: #8E9EB5;
        }
      }

      tbody tr td {
        padding: 10px 15px;
        font-size: size(14px);
        color: #435875;
        align-items: start;
        vertical-align: top;
      }
    }
  }
}