@import './variables.scss';
@import './functions.scss';

.title {
  font-weight: 400;
  margin-top: 0;

  &--lg {
    font-weight: 300;
    font-family: $ff-averta;
    font-size: size(58px);
    line-height: size(71px);

    span {
      color: $color-dodger-blue
    }
  }

  &--black {
    color: $color-nero;
    font-size: size(42px);
    line-height: size(52px);
    font-family: $ff-averta;
    margin-bottom: size(20px);
  }

  &--uppercase {
    text-transform: uppercase;
    color: $color-ship-cove;
    font-size: size(16px);
    //border-bottom: 2px solid $color-ghost-white-2;
  }
}
