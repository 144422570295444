@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-interactive-options {
  margin-left: auto;
  position: relative;
  display: flex;

  &__metrics {
    margin-right: size(12px);

    .select__block {
      height: size(33px);
      padding: 0 size(32px) 0 size(12px);

      img {
        margin: 0;
        width: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: size(14px);
        height: size(1px);
        width: size(6px);
        background: #435875;
        transform: rotate(-45deg);
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: size(17px);
        height: size(1px);
        width: size(6px);
        background: #435875;
        transform: rotate(45deg);
      }

      &.active {
        &:after {
          transform: rotate(45deg);
        }

        &:before {
          transform: rotate(-45deg);
        }
      }
    }

    .select__content.active {
      top: size(35px);
      left: auto;
      right: 0;
    }
  }

  &__body {
    position: relative;
  }

  &__btn {
    padding: size(5px);
    width: size(32px);
    min-width: size(32px);
    height: size(32px);
  }

  &-popup {
    position: absolute;
    z-index: 3;
    top: size(35px);
    right: size(-5px);
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: size(6px);
    background: #fdfdff;
    //border: size(1px) solid #EFF0F9;
    box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    padding: size(5px);

    &__btn {
      padding: size(5px);
      width: size(32px);
      height: size(32px);
    }
  }
}