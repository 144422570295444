@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: size(22px);

  label {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    font-size: size(16px);
    color: $color-chambray;

    input,
    textarea {
      flex-grow: 1;

      width: 100%;
      padding: size(13px) size(40px) size(13px) size(28px);

      border: none;
      border-radius: size(10px);
      outline: none;
      background-color: $color-alice-blue;

      color: $color-chambray;
      font-size: size(14px);

      transition: background-color $trans-default,
      border $trans-default;

      &:-webkit-autofill {
        box-shadow: inset 0 0 0 1000px $color-alice-blue;

        -webkit-text-fill-color: $color-default-black;
      }

      &::placeholder {
        color: $color-chambray;
      }

      &:hover,
      &:focus {
        background-color: $color-solitude;
      }

      &:disabled {
        background-color: rgba($color-link-water, 0.4);

        &::placeholder {
          color: $color-ship-cove;
        }
      }
    }
  }

  &.is-data {
    svg {
      pointer-events: none;
    }
  }

  &__label {
    position: relative;
    margin-bottom: size(10px);
    display: flex;
    align-items: center;
    gap: size(8px);
    .tooltip {
      margin: 0;
    }
    span.red {
      color: #E56384;
    }
  }

  &__icon {
    position: absolute;
    width: size(24px);
    height: size(24px);
    //top: size(37px);
    top: 50%;
    transform: translateY(-50%);
    right: size(28px);
    fill: $color-transparent;

    cursor: pointer;
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: size(-3px);
    color: $color-torch-red;

    transform: translateY(size(18px));
    opacity: 0;

    user-select: none;
    pointer-events: none;
    transition: opacity $trans-default;
  }

  &__tip {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    left: 0;
    top: size(74px);
    z-index: 1;
    background: #fdfdff;
    border: size(1px) solid #EFF0F9;
    box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    padding: size(14px) size(10px);
    max-height: size(340px);
    overflow-y: auto;
    &.top {
      top: auto;
      bottom: size(74px);
    }
    button {
      width: 100%;
      background: transparent;
      border: none;
      border-radius: size(6px);
      padding: size(12px);
      text-align: left;
      margin-bottom: size(5px);
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background: #EDF3FC;
      }
    }
  }

  &__password-switch {
    position: absolute;
    bottom: size(9px);
    right: size(26px);

    display: flex;
    justify-content: center;
    align-items: center;
    width: size(22px);
    height: size(22px);
    padding: 0;

    background-color: $color-transparent;
    border: none;

    cursor: pointer;
    transition: opacity $trans-default;

    svg {
      width: size(18px);
      height: size(18px);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__sub-btn {
    padding: 0;
    margin-top: size(12px);
    margin-left: auto;

    font-size: size(16px);
    line-height: size(19px);
    text-decoration: none;
    color: $color-rock-blue;

    background-color: $color-transparent;
    border: none;

    cursor: pointer;
    transition: opacity $trans-default;

    &:hover {
      opacity: 0.7;
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    flex-shrink: 0;
    transition: transform $trans-default;
  }

  &.is-invalid {
    input {
      background-color: $color-pink-lace;
    }

    .custom-input__error {
      position: relative;

      transform: translateY(0);
      opacity: 1;
    }
    &.is-invalid-absolute {
      .custom-input__error {
        position: absolute;
        transform: translateY(size(18px));
      }
    }
  }

  &.is-disabled {
    .custom-input__icon {
      pointer-events: none;
      cursor: default;
    }
  }

  .custom-input__rdp {
    position: absolute;
    top: size(65px);
    right: size(8px);
    z-index: 2;

    padding-bottom: 20px;

    // background-color: $color-default-white;
    // border-radius: size(4px);
    // box-shadow:
    //         0 size(1px) size(10px) rgb(0 0 0 / 4%),
    //         0 size(4px) size(5px) rgb(0 0 0 / 6%),
    //         0 size(2px) size(4px) size(-1px) rgb(0 0 0 / 9%);
    animation-name: fade-in;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
    //overflow: hidden;
  }

  .custom-input__rdp-inner {
    padding-bottom: 1px;

    background-color: $color-default-white;
    border-radius: size(4px);
    box-shadow:
            0 size(1px) size(10px) rgb(0 0 0 / 4%),
            0 size(4px) size(5px) rgb(0 0 0 / 6%),
            0 size(2px) size(4px) size(-1px) rgb(0 0 0 / 9%);
  }
}

.custom-input--password-switch {
  label {
    input {
      padding-right: size(51px);
    }
  }
}

.custom-input--search {
  label {

    input {
      padding-top: size(18px);
      padding-bottom: size(18px);
      padding-left: size(51px);

      font-family: 'Averta CY';
      font-size: size(16px);
      line-height: size(20px);

      background-color: $color-default-white;
      border-radius: 0;

      &:-webkit-autofill {
        box-shadow: inset 0 0 0 1000px $color-default-white;
      }

      &::placeholder {
        color: $color-spindle;
      }

      &:hover,
      &:focus {
        background-color: $color-alice-blue;
      }
    }

    .custom-input__icon ~ input {
      padding-left: size(47px);
    }
  }

  .custom-input__icon {
    width: size(14px);
    height: size(14px);
    top: size(21px);
    left: size(24px);
  }
}

.custom-input--select {
  margin-bottom: 0;

  label input {
    padding-right: size(46px);
  }

  .custom-input__icon ~ input {
    padding-left: size(30px);
  }

  .custom-input__icon {
    width: size(13px);
    height: size(8px);
    top: auto;
    bottom: size(17px);
    right: size(32px);
    color: $color-ship-cove;
  }
}

.custom-input.has-login-input {
  margin-bottom: size(20px);

  &:last-of-type {
    margin-bottom: size(60px);
  }

  label span {
    visibility: hidden;
    height: 0;
    margin: 0;
  }

  input {
    width: 100%;
    padding: size(10px) 0;
    background-color: $color-transparent;
    font-size: size(22px);
    border-bottom: 1px solid $color-link-water;
    border-radius: 0;
    font-family: $ff-averta;

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 1000px $color-default-white;
    }

    &::placeholder {
      color: $color-rock-blue;
    }

    &:hover,
    &:focus {
      border-bottom: 1px solid $color-dodger-blue;
      background-color: $color-transparent;
    }
  }

  //input:-webkit-autofill {
  //  box-shadow: inset 0 0 0 50px $color-ghost-white;
  //  -webkit-text-fill-color: $color-chambray;
  //}

  &__error {
    bottom: size(-20px);
  }

   &.is-invalid {
     input {
       background-color: $color-transparent;
       border: none;
       border-bottom: 1px solid $color-torch-red;

       &:hover,
       &:focus {
         border: none;
         border-bottom: 1px solid $color-torch-red;
       }
     }
   }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, size(15px), 0);
  }
}

.color--dark {
  label input {
    color: $color-chambray;
    background-color: rgba(67, 88, 117, 0.05);
  }
}