@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-interactive-head {
  width: 100%;
  background: rgba(67, 88, 117, 0.05);
  height: size(44px);
  min-height: size(44px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 size(22px);
  border-bottom: 1px solid rgba(67, 88, 117, 0.08);
  border-radius: size(12px) size(12px) 0 0;
  &__name {
    color: #8E9EB5;
    font-size: size(14px);
    text-transform: uppercase;
  }
  &__tabs {

  }
  &__subtitle {

  }
  &__agg {
    margin-left: size(12px);
    margin-right: size(12px);
    display: flex;
    .select__block {
      height: size(33px);
    }
    .select__content.active {
      top: size(35px);
    }
  }
}