@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.input {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  background: #ECF3FD;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.add-email {
  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
      width: 49%;
    }
  }
  &__block {
    position: relative;
    margin-bottom: 30px;
    & .input_section {
      position: relative;
      input {
        padding-left: 54px;
        margin: 0;
        cursor: pointer;
      }
    }
    &.is-invalid {
      input {
        background-color: $color-pink-lace;
      }

      .custom-input__error {
        position: relative;

        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &__select {
    .select__block {
      color: rgba(67, 88, 117, 1);
      &:after,
      &:before {
        background: rgba(67, 88, 117, 1);
      }
    }
  }
  &-search {
    position: absolute;
    left: 0;
    top: 58px;
    z-index: 2;
    width: 100%;
    max-height: 256px;
    overflow-y: auto;
    background: #F9FAFF;
    border: 1px solid #EFF0F9;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    display: none;
    flex-direction: column;
    &.active {
      display: flex;
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding: 13px 25px;
      cursor: pointer;
      border: none;
      background: transparent;
      border-top: 1px solid rgba(249, 250, 255, 0.4);;
      &:hover {
        background: #EDF3FC;
      }
      &:first-child {
        border: none;
      }
    }
    &__name {
      font-size: 14px;
      color: #435875;
      margin-bottom: 2px;
    }
    &__email {
      color: #7D8DA4;
      font-size: 12px;
    }
  }
  &__icon {
    position: absolute;
    left: 28px;
    top: 50%;
    transform:translate(0, -50%);
    width: 14px;
    margin: 0;
    img {
      width: 100%;
    }
  }
}

.add-email-root {
  &__user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    border: 2px solid #EFF0F9;
    border-radius: 10px;
    padding: 13px 25px;
    margin-bottom: 30px;
    &.not-found {
      background: rgba(67, 88, 117, 0.05);
      color: #435875;
      font-size: 16px;
      text-align: center;
      align-items: center;
      border: none;
    }
  }
  &__name {
    font-size: 14px;
    color: #435875;
    margin-bottom: 2px;
  }
  &__email {
    color: #7D8DA4;
    font-size: 12px;
  }
}


.edit-user {
  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__icon {
    margin-left: auto;
    margin-right: 28px;
    width: 13px;
    &_l {
      width: 22px;
    }
    & img {
      width: 100%;
      margin: 0;
    }
  }
}


.input_section {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  background: #ECF3FD;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.input_error {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  background: #EAC5CF;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
}

.select_wrapper {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: 10px;
  background: #ECF3FD;
  &:hover {
    box-shadow: 0 0 3px gray;
  }
}

.select_arrow {
  content: '';
  position: absolute;
  top: 20px;
  right: 28px;
  bottom: 17px;
  border: 4px solid transparent;
  border-top-color: #6C7231;
  border-bottom: 0;
}

.select_wrapper select {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  padding-right: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  appearance: none;
}