@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.form-search {
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .custom-input {
    height: 100%;
    margin: 0;

    label {
      margin: 0;
    }

    input {
      background-color: $color-ghost-white;
      border-radius: 12px 0 0 0;
    }
  }
}
