@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.tag {
  margin: 0 size(8px) size(10px) 0;
  display: flex;
  border-radius: 4px;
  background-color: $color-alice-blue;

  p {
    padding: size(7px) size(16px) size(7px) size(14px);
    margin: 0;
    font-size: size(12px);
    line-height: size(15px);
    height: fit-content;
  }

  .btn--reset {
    width: size(29px);
    height: 100%;
  }
}
