@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.edit-user {
  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__icon {
    margin-left: auto;
    margin-right: 28px;
    width: 13px;
    &_l {
      width: 22px;
    }
    & img {
      width: 100%;
      margin: 0;
    }
  }
  &__select {
    .select__block {
      color: rgba(67, 88, 117, 1);
      &:after,
      &:before {
        background: rgba(67, 88, 117, 1);
      }
    }
  }
}


.input_section {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  background: #ECF3FD;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.input_error {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  background: #EAC5CF;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
}

.select_wrapper {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: 10px;
  background: #ECF3FD;
  &:hover {
    box-shadow: 0 0 3px gray;
  }
}

.select_arrow {
  content: '';
  position: absolute;
  top: 20px;
  right: 28px;
  bottom: 17px;
  border: 4px solid transparent;
  border-top-color: #6C7231;
  border-bottom: 0;
}

.select_wrapper select {
  width: 410px;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  padding-right: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  appearance: none;
}

.edit-user-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  button {
    width: 49%;
  }
}