@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';
.campaigns__link {
  background: transparent;
  font-size: size(18px);
  font-weight: 400;
  &:hover {
    background: transparent;
    color: rgba(0, 123, 255, .8);
  }
}