@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/mixins';

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;

    &::-webkit-scrollbar {
        display: none;
    }

    &__wrapper {
        position: relative;

        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
        padding: size(40px) size(40px);

        @include vp-767 {
            padding: 60px 16px;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba($color-ghost-white, 0.4);
        cursor: pointer;
    }

    &__content {
        position: relative;

        width: size(470px);
        min-height: size(266px);
        border-radius: size(12px);
        background: $color-ghost-white;
        border: size(2px) solid $color-ghost-white-2;
        box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);

        @include vp-767 {
            width: 100%;
            padding: 20px;
        }
    }

    &__head {
        border-bottom: size(2px) solid $color-ghost-white-2;
        padding: size(19px) size(60px) size(18px) size(29px);
        margin: 0 0 size(32px);
        display: flex;
        align-items: center;

        .dashboard-page-filter {
            position: static;
            margin-bottom: 0;
        }
    }

    .title {
        text-transform: uppercase;
        color: $color-ship-cove;
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 0;
    }

    &__body {
        padding: 0 size(23px) size(22px);
        .tooltip__alt {
            max-width: size(220px);
        }
    }

    &__close-btn.btn {
        position: absolute;
        top: size(7px);
        right: size(16px);
        z-index: 1;

        width: size(40px);
        height: size(40px);
        padding: 0;

        //background: $color-transparent;
    }

    &--responsive {
        .modal__content {
            width: 100%;
        }
    }

    &--fit-content {
        .modal__content {
            width: auto;

            @include vp-767 {
                width: 100%;
            }
        }
    }

    &--height-auto {
        .modal__content {
            min-height: auto;
        }
    }

    &--no-scale {
        transform: none;
    }

    &--preload {
        transition: none;
    }

    &.is-close {
        animation-name: fadeOut;
        animation-duration: 0.3s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
    }

    &--title-center {
        .modal__content {
            width: size(451px);
        }
        .modal__head {
            padding-right: size(29px);
            margin-bottom: size(45px);
        }

        .title {
            text-align: center;
        }

        .modal__close-btn {
            display: none;
        }
    }

    &--info {
        .modal__content {
            width: size(360px);
            min-height: size(178px);
        }
        .modal__head {
            display: none;
        }

        .title {
            display: none;
        }

        .modal__close-btn {
            display: none;
        }
    }

    &--new-campaign,
    &--new-platform {
        .modal__wrapper {
            height: 100%;
        }

        .modal__content {
            height: 100%;
            max-height: size(718px);
        }

        .modal__head {
            margin-bottom: size(25px);
            padding-left: size(30px);
        }
        .title {
            text-align: start;
        }

        .modal__body {
            height: calc(100% - #{size(80px)});
            padding: 0 size(5px) size(22px) size(10px);
        }

        .modal__form {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        form {
            height: calc(100% - #{size(70px)});

            .form__wrapper {
                padding: size(8px) size(12px) size(22px) size(15px);
                height: 100%;

                overflow-y: auto;

                /*Кастомный скроллбар*/

                @include custom-scroll;

                textarea {
                    min-height: size(100px);
                }
            }

            .form__buttons {
                padding: 0 size(15px);
            }
        }

        .select {
            margin-bottom: size(22px);
        }

        .dropdown-list {
            border: none;
        }

        .modal__btn-wrapper {
            position: relative;
            padding-right: size(16px);
            padding-left: size(15px);

            &::before {
                position: absolute;
                content: "";
                width: size(466px);
                height: size(22px);
                top: size(-21px);
                left: size(-15px);

                background: linear-gradient(360deg, $color-ghost-white 0%, rgba(249, 250, 255, 0) 97.92%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }

    &--edit-project {
        .modal__body {
            padding: 0 size(14px) size(20px);
        }

        .modal__form {
            padding: 0;
        }
    }

    &--new-source {
        .modal__head {
            margin-bottom: size(20px);
            padding-left: size(30px);
        }
        .title {
            text-align: start;
        }
    }

    &--new-platform {
        .modal__head {
            margin-bottom: size(24px);
        }
        .title {
            //margin-bottom: size(24px);
        }
    }

    &--loader {
        .modal__overlay {
            pointer-events: none;
        }
    }

    &.right {
        .modal__content {
            height: 100%;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            width: size(584px);
        }
        .modal__body {
            flex-grow: 1;
        }
        .modal__wrapper {
            justify-content: flex-end;
            padding: 0;
            align-items: flex-start;
        }
    }
    &--big-size {
        .modal__content {
            width: size(800px);
        }
    }
    &--full {
        &-size {
            .title {
                display: none;
                margin-bottom: 0;
            }
            .modal__wrapper {
                padding: 0;
            }

            .modal__content {
                width: 100%;
                height: 100%;
                border-radius: 0;
                display: flex;
                flex-direction: column;
            }
            .modal__body {
                flex-grow: 1;
            }
            .dashboard-fullscreen {
                height: 100%;
                max-height: calc(100vh - 89px);
                overflow-y: auto;
                .table-pagination__table {
                    flex-grow: 1;
                    overflow-y: auto;
                }
                .table-pagination__scroll {
                    max-height: calc(100% - 72px);
                    height: 100%;
                    &.no-of {
                        max-height: none;
                        height: auto;
                    }
                }
                .table-pagination__scroll.with_total {
                    max-height: calc(100% - 72px - 44px);
                }
                .table-pagination .empty.absolute {
                    inset: 0;
                }
            }
        }
        &-body {
            .modal__head {
                margin-bottom: 0;
            }
            .modal__body {
                padding: 0;
            }
            .modal__content {
                .modal__close-btn.btn {
                    top: size(24px);
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        transform: scale(1.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
