@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.dashboard-output {
  position: relative;
  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    line-height: size(17px);
    text-align: left;
    width: 100%;
    white-space: nowrap;
  }
}