@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-interactive-labels {
  z-index: 2;
  position: sticky;
  top: 0;
  &__stretch {

  }
  &__items {
    display: flex;
    justify-content: space-between;
  }
}