@import './variables.scss';
@import './functions.scss';

.create {
  width: 100%;
  max-width: size(301px);
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &_in-block {
    flex-grow: 1;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    flex-grow: 1;
    img {
      width: 80px;
      margin-bottom: 12px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: #8E9EB5;
    }
  }

  &.title {
    font-weight: 400;
  }

  button {
    width: 100%;
    height: size(70px);
  }
}