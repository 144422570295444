@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.custom-select {
  position: relative;

  width: 100%;
  margin-bottom: size(22px);

  &__label {
    position: absolute;
    top: 50%;
    left: size(10px);

    transform: translateY(-50%);

    transition: transform $trans-default, opacity $trans-default;

    //pointer-events: none;
    span.red {
      color: #E56384;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: size(10px);

    transform: translateY(-50%);

    transition: transform $trans-default, opacity $trans-default;

    pointer-events: none;
  }

  select {
    position: absolute;

    width: size(1px);
    height: size(1px);
    margin: size(-1px);
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: size(42px);
    padding: size(12px) size(32px) size(12px) size(28px);

    background-color: $color-alice-blue;
    border-radius: size(10px);
    border: none;
    outline: none;
    cursor: pointer;
    color: $color-chambray;
    font-size: size(14px);

    transition: background-color $trans-default;

    .custom-select__text svg {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: $color-solitude;
    }
  }

  &__list {
    position: absolute;
    bottom: size(-8px);
    z-index: 2;

    width: 100%;
    margin: 0;
    padding: size(22px) size(14px);
    overflow-y: auto;

    list-style: none;

    background-color: $color-ghost-white;
    box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility $trans-default, opacity $trans-default;

    .custom-select__add-btn {
      margin-top: size(8px);
    }
  }

  &__error {
    pointer-events: none;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: size(10px) size(14px);
    margin-bottom: size(2px);

    border-radius: size(10px);

    outline: none;
    cursor: pointer;

    color: $color-chambray;
    font-size: size(14px);

    transition: background-color $trans-default;

    svg {
      width: size(12px);
      height: size(8px);
      margin-left: auto;
      opacity: 0;

      pointer-events: none;
      transition: opacity $trans-default;
    }

    &-edit {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      background: transparent;
      border: none;
      width: size(28px);
      height: size(28px);
      //border: 2px solid #007BFF;
      border-radius: size(6px);
      cursor: pointer;
      margin-left: size(8px);
      svg {
        height: size(12px);
        margin: 0;
        padding: 0;
        opacity: 1;
      }
      &:hover {
        background-color: $color-solitude;
      }
    }
    &-remove {
      svg path {
        fill: $color-deep-blush;
      }
    }

    &.is-selected,
    &[aria-selected="true"] {
      background-color: $color-alice-blue;
      color: $color-dodger-blue;
      display: flex;
      align-items: center;

      svg {
        opacity: 1;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: $color-solitude;
          .custom-select__item-edit:hover {
            background: $color-alice-blue;
          }
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-alice-blue;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: -0.5rem;
    left: 0;

    color: $color-torch-red;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    user-select: none;
    pointer-events: none;
    transition: opacity $trans-default 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    width: size(13px);
    height: size(8px);
    margin-left: auto;
    color: $color-ship-cove;

    svg {
      width: size(13px);
      height: size(8px);
    }

    transition: transform $trans-default;
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg) translateZ(0);
    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.open-up {
    .custom-select__icon {
      transform: rotate(180deg) translateZ(0);
    }

    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + #{size(26px)}));
    }

    &.is-open {
      .custom-select__icon {
        transform: rotate(0deg) translateZ(0);
      }
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      position: relative;
      opacity: 1;
    }

    .custom-select__button {
      border: size(2px) solid $color-torch-red;

      &:focus,
      &:hover {
        border: size(2px) solid rgba($color-torch-red, 0.5);
      }
    }

    .custom-select__list {
      bottom: size(12px);
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      position: static;
      display: inline-flex;
      margin-top: size(10px);
      opacity: 1;
      align-items: center;
      gap: size(8px);

      .tooltip {
        margin: 0;
      }
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - #{size(40px)}));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }
}
