@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.information {
  p {
    font-size: size(16px);
    margin: 0 0 size(10px) 0;
  }
  &__table {
    margin-bottom: size(10px);
  }
  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__link {
    font-size: size(16px);
    cursor: pointer;
    color: $color-dodger-blue;

    &:hover {
      text-decoration: underline;
    }
  }
  &__list {
    padding-left: size(20px);
  }
  &__underlist {
    padding-left: size(40px);
  }
  ul li {
    margin-bottom: size(6px);
  }
}