@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.edit-multi-actions {
  &__btns {
    display: flex;
    flex-direction: column;
    gap: size(20px);
  }
  &__different {

  }
  &__text {
    margin: 0;
    padding: 0;
    color: $color-spindle;
    font-size: 14px;
  }
  .custom-input,
  .custom-select {
    margin-bottom: 0;
  }
  &__field {
    margin-bottom: size(20px);
  }
}