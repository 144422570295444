@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.integrations-modal__block {
  padding: 0;
}
.integrations-modal__title {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #435875;
}
.integrations-modal__close {
  display: flex;
  border: none;
  background: none;
  color: #8E9EB5;
  cursor: pointer;
  position: relative;
  height: 16px;
  width: 16px;
  &:after {
    content: "";
    height: 2px;
    width: 16px;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #8E9EB5;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all .3s ease-in;
  }
  &:before {
    content: "";
    height: 2px;
    width: 16px;
    top: 8px;
    left: 0;
    position: absolute;
    background-color: #8E9EB5;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all .3s ease-in;
  }
}
.integrations-modal__input_section {
  span {

  }
  input {

  }
}
.integrations-modal__action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  button {
    width: 49%;
  }
}


.custom-input--search {
  margin-bottom: 0;
  label input {
    height: 42px;
    border: none;
    border-radius: 10px;
    background: #EAF3FD;
    color: #899FB7;
    padding: 12px 28px;
  }
  .custom-input__icon {
    top: 50%;
    transform:translate(0, -50%);
  }
}