@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.table-interactive {
  background: #FDFDFF;
  border-radius: 0.75rem;
  border: size(1px) solid rgba(67, 88, 117, 0.08);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: size(350px);
  width: 100%;
  &-data {
    display: flex;
    flex-direction: column;
    border-radius: size(12px);
    font-size: size(14px);
    background: #FDFDFF;
    border-collapse: collapse;
    text-align: left;
    margin: 0 auto;
    width: 100%;
    position: relative;
    overflow-x: auto;
    flex-grow: 1;
    @include custom-scroll;

    &.no-scrollbar {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
      }
    }
    &.no-of {
      overflow-y: hidden;
    }
  }
}