@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.accordion {
  margin-top: -3px;
  border-top: 2px solid $color-ghost-white-2;

  &.is-active {
    .accordion__content {
      padding: size(20px) 0;
      height: auto;
      opacity: 1;
      overflow: visible;
    }

    .accordion__btn {
      background-color: $color-transparent;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    width: 100%;
    padding: size(24px) size(15px) size(23px) 0;
    min-height: size(70px);
    background-color: $color-transparent;
    color: $color-chambray;
    font-size: size(18px);
    justify-content: space-between;
    border-radius: 0;
    //border-bottom: 2px solid $color-ghost-white-2;

    .btn__text {
      margin-left: 0;
      font-size: size(18px);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-transparent;
      }
    }

    svg {
      order: 1;
      fill: $color-transparent;
      width: size(20px);
      height: size(10px);
      transition: transform $trans-default;
    }
  }

  &__content {
    height: 0;
    opacity: 0;
    border-bottom: 2px solid #EFF0F9;
    overflow: hidden;
  }

  .custom-select {
    //margin-bottom: 0;
  }

  & + & {
    border-top: none;
  }
}
