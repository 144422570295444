@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.settings_wrapper {
  display: flex;
  flex-direction: column;
  border-width: size(2px) size(2px) 0 size(2px);
  border-style: solid;
  border-color: #EFF0F9;
  border-radius: size(12px) size(12px) 0 0;
  min-width: calc(100vw - #{size(120px)});
  padding: 0 size(2px);
  height: calc(100vh - #{size(90px)});
}

.ProjectSettings {
  display: flex;
  align-items: center;
  padding: size(20px) size(32px);
  //margin-bottom: 16px;
  border-width: 0 0 size(2px) 0;
  border-style: solid;
  border-color: #EFF0FA;
  max-height: size(90px);
}

.link_item a {
  min-height: size(50px);
  padding: size(16px) size(20px);
  margin: size(20px) 0;
  font-size: size(14px);
  white-space: nowrap;
}

.link_selected {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: size(50px);
  padding: size(16px) size(20px);
  color: #007DFF;
  background: #ECF3FD;
  border-radius: size(12px);
}

.foo {
  //margin-left: 16px;
  //margin-top: 32px;
  height: calc(100% - #{size(90px)});
}

.upload__unavailable {
  height: size(49px);
  background: rgba(255, 199, 55, 0.1);
  padding-left: size(28px);
  display: flex;
  align-items: center;
  font-size: size(14px);
  line-height: size(17px);
  color: #435875;
}