@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';
.table-row {
  // user-select: none;

  &__checkbox {
    width: fit-content;
  }

  &__status {
    min-width: size(68px);
    align-self: stretch;

    svg {
      width: size(23px);
      height: size(23px);
    }
  }

  &__unit {
    color: rgba($color-chambray, 0.8)
  }

  &__name {
    font-weight: 500;
    margin-top: -2px;

    word-break: break-word;

    span {
      max-width: size(338px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .role {
      font-weight: 500;
      font-size: size(16px);
      line-height: size(19px);
      color: #8E9EB5;
      text-transform: lowercase;
      margin-left: size(8px);
    }
  }

  &--pointer {
    cursor: pointer;
  }
  &__setting {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      padding: 0;
      margin: 0;
    }
  }
  &__author {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: $color-chambray;
      font-size: 12px;
    }
  }
}
