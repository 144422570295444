@import './variables.scss';
@import './functions.scss';
@import './mixins.scss';

.container {
  width: 100%;
  max-width: size(1920px);
  min-width: 320px;
  margin: 0 auto;
  padding: 0 size(24px);

  &--no-padding {
    padding: 0;
  }

  // @include vp-1439 {
  //   padding: 0 32px;
  // }

  // @include vp-1023 {
  //   padding: 0 24px;
  // }

  // @include vp-767 {
  //   padding: 0 16px;
  // }
}
