@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.table {
  width: 100%;
  // min-height: calc(100vh - #{size(150px)});
  // min-height: calc(100 * var(--vh, 1vh) - #{size(150px)});

  table {
    width: 100%;
    height: 100%;
    // min-height: calc(100vh - #{size(150px)});
    // min-height: calc(100 * var(--vh, 1vh) - #{size(150px)});
    // height: 100vh;

    border: size(2px) solid $color-ghost-white-2;
    border-top: none;
    border-collapse: collapse;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    position: relative;
    &:hover .table__choice{
      display: flex;
    }
  }
  &__choice {
    display: flex;
    align-items: center;
    justify-content: center;
    //padding: 0 size(12px);
    //position: absolute;
    //top: size(18px);
    //height: size(51px);
    color: $color-chambray;
    //background: #F9FAFF;
    //border: size(2px) solid #EFF0F9;
    border-radius: size(10px);
    //left: 0;
    z-index: 1;
    &:hover {
      display: flex;
    }
    .custom-toggle {
      padding: 0;
      margin: 0;
      label {
        margin: 0;
      }
    }
  }

  tr {
    display: grid;
    grid-template-columns: min-content min-content 26.9% 9.1% 9.1% 10.4% 10.3% 9.7% 10.2% 4.7%;
    min-height: size(51px);
    padding: 0 size(24px);
    border-bottom: size(1px) solid $color-ghost-white-2;
    align-items: center;
    transition: background-color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-alice-blue;
      }
    }

    &.is-delete {
      td {
        opacity: 0.6;
      }

      .table-row__status svg {
        margin-left: size(5px);
        width: size(16px);
        height: size(16px);
      }
    }

    &.is-archived {
      .table-row__status svg {
        margin-left: size(3px);
      }
    }

    .dropdown-list__item .btn {
      width: 100%;
    }
  }

  th {
    color: $color-rock-blue;
    font-size: size(14px);
    font-weight: 500;
    padding-left: size(15px);
    cursor: pointer;

    &:first-of-type {
      padding-left: 0;
      min-width: size(46px);
      cursor: default;
      svg.table__arrow {
        //display: none;
      }
    }
    &.not-sort {
      cursor: default;
      svg.table__arrow {
        display: none;
      }
    }

    span {
      margin-right: size(4px);
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &:hover {
      svg path {
        fill: #8E9EB5;
      }
    }
    &.ascending {
      svg path {
        fill: #007BFF;
      }
    }
    &.descending {
      svg {
        transform: rotate(180deg);
        path {
          fill: #007BFF;
        }
      }
    }
  }

  td {
    padding: 0;
    padding-left: size(20px);
    display: flex;
    align-items: center;

    .btn--icon {
      width: size(38px);
      height: size(38px);
      padding: 0;
      background-color: $color-ghost-white;
    }

    .custom-toggle {
      width: size(46px);
      padding: 0;
    }

    &:first-of-type {
      padding-left: 0;
    }
  }

  &--header {
    min-height: size(50px);

    tr {
      text-align: left;
      min-height: size(48px);
      border-bottom: none;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: $color-ghost-white;
        }
      }
    }
  }

  &--project {
    tr {
      grid-template-columns: min-content 29.3% 11.9% 14.3% 13.3% 12.2% 10% 5%;
    }
  }

  &.scrolled {
    height: calc(100vh - #{size(200px)});

    overflow-y: auto;
    overflow-x: hidden;

    /*Кастомный скроллбар*/

    @include custom-scroll;
  }
}
