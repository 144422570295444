@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.user-control {
  //margin: -48px 0 0 -16px;
  height: 100%;
  &__unavailable {
    height: size(49px);
    background: rgba(255, 199, 55, 0.1);
    padding-left: size(28px);
    display: flex;
    align-items: center;
    font-size: size(14px);
    line-height: size(17px);
    color: #435875;
    border-bottom: size(2px) solid #EFF0F9;
  }
  &__header {
    display: flex;
    .form-search {
      width: calc(100% - #{calc(20px)});
      border-bottom: size(2px) solid #EFF0F9;
      & input:hover,
      & input:focus, {
        background: transparent;
      }
    }
  }
  &__openPopup {
    width: size(60px);
    border: none;
    background: transparent;
    border-bottom: size(2px) solid #EFF0F9;
    border-left: size(2px) solid #EFF0F9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      display: flex;
      width: size(20px);
      height: size(20px);
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: size(9px);
        left: 0;
        transform: rotate(90deg);
        width: size(20px);
        height: size(2px);
        background: #007BFF;
      }
      &:before {
        content: '';
        position: absolute;
        top: size(9px);
        left: 0;
        width: size(20px);
        height: size(2px);
        background: #007BFF;
      }
    }
  }
  &-table {
    height: calc(100% - #{size(46px)});
    &__head {
      height: size(50px);
      border-bottom: size(1px) solid #EFF0F9;
      display: flex;
      align-items: center;
      padding: 0 0 0 size(20px);

    }
    &__title {
      font-size: size(14px);
      color: #8E9EB5;
      &:first-child {
        //width: 18.5%;
        width: 25%;
        margin-left: size(32px);
      }
      &:nth-child(2) {
        //width: 17.74%;
        width: 25%;
      }
      &:nth-child(3) {
        //width: 24.89%;
        width: auto;
      }
      &:nth-child(4) {
        //width: 14.74%;
        width: 25%;
      }
      //&:last-child {
      //  width: 24.89%;
      //}
    }
    &-list {
      display: flex;
      flex-direction: column;
      height: calc(100% - #{size(50px)});
      overflow-y: auto;
      li {
        padding: size(12px) 0;
        border-top: size(1px) solid #EFF0F9;
        &:hover {
          background: rgba(67, 88, 117, 0.02);
          & .user-control-table-list__icon img {
            display: flex;
          }
          & .user-control-table-list__delete {
            display: flex;
          }
        }
      }
      &__item {
        padding-left: size(20px);
        display: flex;
        align-items: center;
      }
      &__icon {
        width: size(12px);
        margin-right: size(20px);
        img {
          width: 100%;
          display: none;
        }
      }
      &__icon_empty {
        width: size(12px);
        margin-right: size(20px);
      }
      &__user {
        //width: 18.5%;
        width: 25%;

      }
      &__block {
        width: 60%;
        height: size(58px);
        background: rgba(67, 88, 117, 0.05);
        border-radius: size(10px);
        padding: size(12px) size(14px) size(12px) size(22px);
        display: flex;
        align-items: center;
        &:hover {
          width: 75%;
          .user-control-table-list__edit {
            display: flex;
          }
        }
        &.noedit {
          width: 60%;
          & .user-control-table-list__edit {
            display: none;
          }
        }
      }
      &__wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      &__name {
        color: #435875;
        font-size: size(14px);
        margin-bottom: size(2px);
      }
      &__email {
        font-size: size(12px);
        color: #435875;
        opacity: 0.6;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__edit {
        margin-left: auto;
        width: size(29px);
        height: size(29px);
        display: none;
        align-items: center;
        justify-content: center;
        background: rgba(67, 88, 117, 0.05);
        border: none;
        border-radius: size(6px);
        cursor: pointer;
        img {
          width: size(11px);
          margin: 0;
        }
      }
      &__role {
        //width: 17.74%;
        width: 25%;
        &-wrapper {
          width: 80%;
          position: relative;
          & .user-control-table-list__text {
              display: none;
          }
          &.noedit {
            height: size(41px);
            background-color: rgba(67, 88, 117, 0.05);
            border-radius: size(10px);
            padding-left: size(28px);
            font-size: size(14px);
            color: #435875;
            display: flex;
            align-items: center;
            & select {
              display: none;
            }
            & .user-control-table-list__arrow {
              display: none;
            }
            & .user-control-table-list__text {
              display: flex;
            }
          }
        }
        select {
          display: block;
          height: size(41px);
          background-color: #EDF3FC;
          border-radius: size(10px);
          padding-left: size(28px);
          font-size: size(14px);
          color: #435875;
          line-height: 1.3;
          width: 100%;
          max-width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          margin: 0;
          border: none;
           -moz-appearance: none;
           -webkit-appearance: none;
           appearance: none;
           &::-ms-expand { display: none; }
           &:hover { background-color: #E1EDFF; }
            &:focus {
              background-color: #E1EDFF;
              outline: none;
            }
            & option { font-weight:normal; }
        }
      }
      &__arrow {
        width: size(29px);
        height: size(29px);
        position: absolute;
        top: size(6px);
        right: size(7px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E5EEFC;
        border-radius: size(6px);
        img {
          width: size(10px);
          margin: 0;
        }
      }
      &__traffic {
        //width: 24.89%;
        width: auto;
        &-block {
          width: 80%;
          background: rgba(67, 88, 117, 0.05);
          border-radius: size(10px);
          padding: size(11px) size(14px) size(11px) size(22px);
          display: flex;
          align-items: center;
          &:hover {
            width: 90%;
            .user-control-table-list__edit {
              display: flex;
            }
          }
          &.noedit {
            width: 80%;
            height: size(41px);
            & .user-control-table-list__edit {
              display: none;
            }
          }
        }
      }
      &__text {
        font-size: size(14px);
        color: #435875;
      }
      &__access {
        //width: 14.74%;
        width: 25%;
        &-block {
          width: 80%;
          & .user-control-table-list__text {
            display: none;
          }
          &.noedit {
            height: size(41px);
            background-color: rgba(67, 88, 117, 0.05);
            border-radius: size(10px);
            padding-left: size(28px);
            font-size: size(14px);
            color: #435875;
            display: flex;
            align-items: center;
            & .user-control-table-list__text {
              display: flex;
            }
          }
        }
        & .custom-input {
          margin: 0;
          & svg {
            top: size(8px);
          }
          & input {
            height: size(41px);
          }
        }
      }
      &__visit {
        width: 15.11%;
      }
      &__delete {
        width: size(32px);
        height: size(32px);
        background: rgba(229, 99, 132, 0.1);
        border-radius: size(8px);
        border: none;
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto;
        margin-right: size(36px);
        img {
          width: size(12px);
          margin: 0;
        }
      }
    }
  }
}
.user-control-time {
  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    height: size(48px);
  }
  &__icon {
    margin-left: auto;
    margin-right: size(28px);
    width: size(13px);
    &_l {
      width: size(22px);
    }
    & img {
      width: 100%;
      margin: 0;
    }
  }
}