@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.last-activity{
  &__list {
    margin-bottom: 22px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__location {
    font-size: 16px;
    color: #435875;
  }
  &__info {
    display: flex;
    align-items: center;
    span {
      color: #8E9EB5;
      font-size: 12px;
      &:first-child {
        margin-right: 13px;
      }
    }
  }
  &__action {

  }
}


