@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.custom-toggle {
  position: relative;

  display: flex;
  padding: 20px 0;

  input {
    position: absolute;

    width: size(1px);
    height: size(1px);
    margin: size(-1px);

    clip: rect(0 0 0 0);
  }

  label {
    position: relative;
    display: flex;
    align-items: center;

    cursor: pointer;

    span {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &__icon {
    position: relative;
    display: inline-flex;
    border: 2px solid $color-transparent;
    border-radius: size(4px);
    transition: border $trans-default;

    svg {
      position: absolute;
      width: size(12px);
      height: size(8px);
      opacity: 0;

      transition: opacity $trans-default;
    }
  }

  &__label {
    padding-left: size(12px);
  }
  &__link {
    cursor: pointer;
    color: $color-dodger-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  input:checked + .custom-toggle__icon {
    svg {
      opacity: 1;
    }
  }

  &--checkbox {
    .custom-toggle__icon {
      width: size(26px);
      min-width: size(26px);
      height: size(26px);
      background: $color-alice-blue;

      svg {
        width: size(12px);
        height: size(8px);
        top: size(8px);
        left: size(6px);
      }
    }

    input:checked + .custom-toggle__icon {
      svg {
        opacity: 1;
      }
    }

    // label {
    //   @media (hover: hover),
    //   screen and (min-width: 0\0) {
    //     &:hover {
    //       input:not(:checked)+.custom-toggle__icon {
    //         border-color: $color-dodger-blue;
    //       }

    //       input:not(:checked)+.custom-toggle__label {
    //         border-color: $color-dodger-blue;
    //       }
    //     }
    //   }
    // }
  }

  &--checkbox-border {
    .custom-toggle__icon {
      width: size(28px);
      min-width: size(28px);
      height: size(28px);

      border-color: $color-link-water;

      svg {
        width: size(12px);
        height: size(8px);
        top: size(8px);
        left: size(6px);
      }
    }

    input:checked+.custom-toggle__icon {
      border-color: $color-dodger-blue;

      svg {
        opacity: 1;
      }
    }

    // label {
    //   @media (hover: hover),
    //   screen and (min-width: 0\0) {
    //     &:hover {
    //       input:not(:checked)+.custom-toggle__icon {
    //         border-color: $color-dodger-blue;
    //       }
    //     }
    //   }
    // }
  }

  &--radio-checkbox {
    label {
      input:checked+.custom-toggle__icon {
        background-color: $color-shamrock;

        &::before {
          transform: translateX(size(15px));
        }
      }

      input:not(:checked)+.custom-toggle__icon {
        background-color: $color-shamrock$color-link-water;

        &::before {
          transform: translateX(0);
        }
      }

      .custom-toggle__icon {
        pointer-events: none;
        width: size(30px);
        height: size(14px);
        border: none;
        border-radius: size(10px);

        background-color: $color-link-water;
        transition: background-color $trans-default;

        &::before {
          content: "";
          position: absolute;
          top: size(2px);
          left: size(2px);
          bottom: size(2px);
          width: size(10px);

          border-radius: 100%;
          background-color: $color-default-white;
          transform: translateX(0);
          transition: transform $trans-default;
        }

        svg {
          display: none;
        }
      }
    }
  }

  &--radio {
    label {
      input:checked+.custom-toggle__icon {
        background-color: $color-shamrock;

        &::before {
          transform: translateX(size(15px));
        }
      }

      input:not(:checked)+.custom-toggle__icon {
        background-color: $color-shamrock$color-link-water;

        &::before {
          transform: translateX(0);
        }
      }
    }

    .custom-toggle__icon {
      pointer-events: none;
      width: size(30px);
      height: size(14px);
      border: none;
      border-radius: size(10px);

      background-color: $color-link-water;
      transition: background-color $trans-default;

      &::before {
        content: "";
        position: absolute;
        top: size(2px);
        left: size(2px);
        bottom: size(2px);
        width: size(10px);

        border-radius: 100%;
        background-color: $color-default-white;
        transform: translateX(0);
        transition: transform $trans-default;
      }
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--gray {
    input:checked + .custom-toggle__icon {
      border-color: $color-link-water;
      svg path {
        fill: $color-link-water;
      }
    }
    .custom-toggle__label {
      padding-left: size(6px);
    }
    .custom-toggle__icon {
      width: size(20px);
      height: size(20px);

      border-color: $color-link-water;
      border-width: size(1px);

      svg {
        width: size(12px);
        height: size(8px);
        top: size(5px);
        left: size(3px);
        path {
          fill: $color-link-water;
        }
      }
    }
  }
  &--small {
    .custom-toggle__icon {
      width: size(20px);
      min-width: size(20px);
      height: size(20px);
      svg {
        width: size(12px);
        height: size(8px);
        top: size(5px);
        left: size(3px);
      }
    }
  }

  /*&.is-invalid {
    .custom-toggle__icon {
      border: 1px solid $color-torch-red;
    }

    .custom-toggle__error {
      opacity: 1;
    }
  }

  &.is-valid {
    label span {
      color: $color-eucalyptus;
    }
  }*/
  &--invalid {
    .custom-toggle__icon {
      border: 1px solid $color-torch-red;
    }

    .custom-toggle__error {
      opacity: 1;
    }
  }
}
