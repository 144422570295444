@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.options {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  &__item {
    border-left: 2px solid $color-ghost-white-2;
    width: size(60px);
    height: size(56px);
    position: relative;

    &:last-of-type {
      .btn--icon {
        border-radius: 0 12px 0 0;
      }
    }

    .popup {
      height: 100%;
    }
  }

  &__item:last-of-type {
    .btn--icon {
      border-top-right-radius: 12px;
    }
  }

  .btn--icon {
    height: 100%;
    max-height: size(56px);
    justify-content: center;
    align-items: center;
    border-radius: 0;

    &:not(:hover) {
      background-color: $color-ghost-white;
    }

    svg {
      width: size(24px);
      height: size(24px);
    }
  }

  .dropdown-list {
    bottom: 0;
  }
}
