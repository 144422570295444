@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';
.content {
  padding: 40px 16px 20px 40px;
  position: relative;
  height: 100%;
}
.wrapper {
  width: 410px;
  display: flex;
  flex-direction: column;

}
.title {
  font-size: size(18px);
  color: #435875;
  margin-top: 0;
  margin-bottom: size(22px);
}
.copy_section {
  display: flex;
  flex-direction: column;
  background: #EDF3FC;
  border-radius: 10px;
  padding: 22px;
  margin-bottom: 22px;
}
.sdk_code {
  background: #F9FAFF;
  border: 2px solid #EFF0F9;
  border-radius: 10px;
  padding: 20px 12px;
  margin-bottom: 12px;
  color: #435875;
  font-size: 14px;
  height: 200px;
}
.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  background: #007DFF;
  color: #FFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
  }
}
.copied {
  width: 100%;
  height: 44px;
  background: #F9FAFF;
  border: 2px solid #EFF0F9;
  color: #435875;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: #FFF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
  }
}
.text {
  font-size: 14px;
  line-height: 17px;
  color: #8E9EB5;
  margin: 0 0 22px 0;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  width: 450px;
  height: 100%;
  border-left: 2px solid #EFF0F9;
  background: #FDFDFF;
}