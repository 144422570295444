@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.login {
  .container {
    display: flex;
    justify-content: space-between;

    height: 100vh;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    width: 50%;
    padding: 15.6vh 0 0;

    overflow: hidden;
  }

  &__title {
    width: size(520px);
    margin: 0;
    padding: 0 0 0 size(106px);
  }

  &__chart {
    width: 100%;
    height: 100%;

    overflow: hidden;

    svg {
      display: block;
      width: 109%;
      height: auto;
    }
  }

  &__wrapper {
    width: 50%;
    display: flex;
    padding: 24.3vh 0 0 size(134px);

    background-color: $color-default-white;
  }

  &__form-wrapper {
    width: size(400px);
    margin-top: size(-16px);
  }

  &__logo {
    margin-bottom: size(32px);

    svg {
      display: block;
      width: size(155px);
      height: size(40px);
      transform: translate3d(0, 0, 0);
    }
  }

  .tabs__controls {
    margin: 0 0 size(60px);
    color: $color-chambray;
  }

  &__content {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .custom-input.has-login-input:last-of-type {
    margin-bottom: size(60px);
  }
}

.recover__title {
  margin: 0 0 size(60px);
  color: $color-chambray;
  font-size: size(22px);
}

.recover-input {
  position: relative;
  margin-bottom: size(35px);
  &:last-of-type {
    margin-bottom: size(60px);
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: size(16px);
    color: $color-chambray;
    cursor: pointer;
    input {
      width: 100%;
      padding: 0.625rem 0;
      font-size: 1.375rem;
      border: none;
      border-bottom: 1px solid #ced4dc;
      border-radius: 0;
      font-family: "Averta CY", "Arial", sans-serif;
      transition: background-color 0.3s ease, border 0.3s ease;
      &:hover,
      &:focus {
        border: none;
        border-bottom: 1px solid #007bff;
        background-color: rgba(255, 255, 255, 0);
        outline: none;
      }
      &::placeholder {
        color: $color-ship-cove;
      }
    }
  }
  &__error {
    position: absolute;
    left: 0;
    bottom: size(-8px);
    color: $color-torch-red;

    transform: translateY(size(18px));
    opacity: 0;

    user-select: none;
    pointer-events: none;
    transition: opacity $trans-default;
  }
  &.is-invalid {
    input {
      border-bottom-color: $color-torch-red;
    }

    .recover-input__error {
      position: relative;

      transform: translateY(0);
      opacity: 1;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: size(70px);
    color: $color-default-white;
    background-color: $color-dodger-blue;
    width: 100%;
    border: none;
    border-radius: size(10px);
    cursor: pointer;


    font-weight: 500;
    font-size: size(16px);
    line-height: size(19px);
    z-index: 1;

    &:hover,
    &:focus {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #007bff;
    }
    span {

    }
  }
}