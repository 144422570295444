@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.login {
  .container {
    display: flex;
    justify-content: space-between;

    height: 100vh;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    width: 50%;
    padding: 15.6vh 0 0;

    overflow: hidden;
  }

  &__title {
    width: size(520px);
    margin: 0;
    padding: 0 0 0 size(106px);
  }

  &__chart {
    width: 100%;
    height: 100%;

    overflow: hidden;

    svg {
      display: block;
      width: 109%;
      height: auto;
    }
  }

  &__wrapper {
    width: 50%;
    display: flex;
    padding: 24.3vh 0 0 size(134px);

    background-color: $color-default-white;
  }

  &__form-wrapper {
    width: size(400px);
    margin-top: size(-16px);
  }

  &__logo {
    margin-bottom: size(32px);

    svg {
      display: block;
      width: size(155px);
      height: size(40px);
      transform: translate3d(0, 0, 0);
    }
  }

  .tabs__controls {
    margin: 0 0 size(60px);
    color: $color-chambray;
  }

  &__content {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .custom-input.has-login-input:last-of-type {
    margin-bottom: size(60px);
  }
}

.recover__title {
  margin: 0 0 size(60px);
  color: $color-chambray;
  font-size: size(22px);
}

.recover__back {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin: 40px 0 0 auto;
  font-size: 22px;
  color: $color-rock-blue;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: $color-dodger-blue;
  }
}