// Colors
// ---------------------------------

// Default

$color-default-black:               #000000;
$color-default-white:               #ffffff;
$color-transparent:                 rgba(255, 255, 255, 0);

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/334482

$color-nero:                        #202020;
$color-night-rider:                 #303030;
$color-charcoal:                    #474747;
$color-chambray:                    #435875;
$color-ship-cove:                   #7d8da4;
$color-rock-blue:                   #8e9eb5;
$color-link-water:                  #ced4dc;
$color-link-water-2:                #c7d1e0;
$color-spindle:                     #b0bdcf;
$color-dodger-blue:                 #007bff;
$color-solitude:                    #e1edff;
$color-solitude-2:                  #e5e9f3;
$color-alice-blue:                  #edf3fc;
$color-ghost-white:                 #f9faff;
$color-ghost-white-2:               #eff0f9;
$color-torch-red:                   #ff1553;
$color-shamrock:                    #2dce8a;
$color-shamrock-lace: #c9ecd9;
$color-pink-lace:                   #f7d0da;
$color-deep-blush:                  #e56384;
$color-warning:                     #FF9900;

// Gradient

$black-to-right:                    linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

// Typography
// ---------------------------------

// переменная используемая в html для подключения скейлинга
$fz-default:                        16px;

// у некоторых шрифтов в ios возникают проблемы с символом рубля
// https://www.artlebedev.ru/kovodstvo/sections/159/#13
$ff-rouble:                         "Rouble", "Arial", sans-serif;

$ff-inter:                          "Inter", "Arial", sans-serif;
$ff-averta:                         "Averta CY", "Arial", sans-serif;

// Animation
// ---------------------------------

$tf-default:                        ease;
$trans-default:                     0.3s $tf-default;

// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal:                       0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600:                         0.6s $tf-default;

// Viewports
// ---------------------------------

$vp-320:                            320px;
$vp-375:                            375px;
$vp-768:                            768px;
$vp-1024:                           1024px;
$vp-1280:                           1280px;
$vp-1440:                           1440px;
$vp-1920:                           1920px;

// Retina
// ---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;
