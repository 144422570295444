@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.breadcrumbs {
  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item:not(:first-child) {
    position: relative;
    padding-left: size(27px);

    &::before {
      content: "/";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: size(11px);
      color: $color-rock-blue;
      font-size: size(14px);
    }

    &:last-child,
    &:only-of-type {
      .breadcrumbs__link {
        pointer-events: none;
      }
    }
  }

  &__item:only-child {
    .breadcrumbs__link {
      pointer-events: none;
    }
  }

  &__link {
    color: $color-rock-blue;
    font-size: size(14px);
    transition: color $trans-default;

    span {
      color: $color-chambray;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-chambray;
      }
    }
  }

  &__view {
    font-weight: 500;
    font-size: size(14px);
    line-height: size(19px);
    color: #8E9EB5;
    text-transform: lowercase;
    margin-left: size(6px);
  }
  &__model {
      //width: 257px;
      //padding: 0 16px;
      position: relative;
      margin-right: 0;
      &_block {
        width: 100%;
        height: size(48px);
        background: #EDF3FC;
        border-radius: size(10px);
        display: flex;
        align-items: center;
        padding-left: size(20px);
        padding-right: size(40px);
        color: $color-chambray;
        position: relative;
        border: none;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: size(10px);
          height: size(1px);
          width: size(10px);
          background: $color-chambray;
          transform: rotate(-45deg);
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: size(17px);
          height: size(1px);
          width: size(10px);
          background: $color-chambray;
          transform: rotate(45deg);
        }
        &.active {
          &:after {
            transform: rotate(45deg);
          }
          &:before {
            transform: rotate(-45deg);
          }
        }
        @media (hover: hover), screen and (min-width: 0\0) {
          &:hover {
            background-color: #E1EDFF;
          }
        }
        & img {
          margin: 0 size(12px) 0 0;
        }
      }
      &_content {
        display: none;
        background: #F9FAFF;
        border: size(1px) solid #EFF0F9;
        box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
        border-radius: size(10px);
        width: 100%;
        padding: size(14px) size(10px);
        &.active {
          min-width: size(209px);
          position: absolute;
          display: flex;
          flex-direction: column;
          top: size(55px);
          left: 0;
          z-index: 100;
        }
        &__btn {
          background: transparent;
          border: none;
          border-radius: size(6px);
          padding: size(8px) size(12px) size(8px) size(12px);
          text-align: left;
          margin-bottom: size(5px);
          cursor: pointer;
          font-size: size(16px);
          overflow: hidden;
          text-overflow: ellipsis;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #EDF3FC;
          }
          &.active {
            background: #EDF3FC;
          }
        }
        .btn {
          padding: 0;
          font-size: size(14px);
          height: size(36px);
        }
      }
    }
  &__btn {
    margin-top: size(12px);
    button {
      width: 100%;
    }
  }
  &__back {
    margin-left: size(20px);
    height: size(35px);
    background: $color-alice-blue;
    border-radius: size(10px);
    display: flex;
    align-items: center;
    padding: 0 size(15px);
    color: $color-chambray;
    font-size: size(14px);
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: #E1EDFF;
      }
    }
  }
}