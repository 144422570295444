@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.popup {
  position: relative;
  height: fit-content;
  align-self: center;

  .dropdown-list {
    right: size(-36px);
    bottom: size(-6px);
    height: 0;
    overflow: hidden;
  }

  &.is-active {
    .dropdown-list {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      height: auto;
      overflow: unset;
    }
  }
}

.popup--center {
  .dropdown-list {
    right: 50%;
    transform: translate(50%, 100%);
  }
}
