@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.select-options {
  display: none;
  background: #fdfdff;
  border: size(1px) solid #EFF0F9;
  box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
  border-radius: size(10px);
  width: max-content;
  padding: size(14px) size(10px);

  &.active {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: size(40px);
    right: 0;
    z-index: 2;
    max-height: size(340px);
    overflow-y: auto;
    &.left {
      right: auto;
      left: 0;
    }
  }

  &__item {
    width: 100%;
  }

  &__btn {
    background: transparent;
    border: none;
    border-radius: size(6px);
    padding: size(12px);
    text-align: left;
    margin-bottom: size(5px);
    cursor: pointer;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: #EDF3FC;
    }

    &.active {
      background: #EDF3FC;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    margin-bottom: size(5px);
    &_input {
      margin-bottom: 0;
      width: max-content;
    }
  }
  &__reset_icon {
    button.btn--icon {
      border-radius: size(10px);
      width: size(32px);
      height: size(32px);
      padding: size(6px);
      margin-left: size(6px);
      svg {
        width: size(16px);
        height: size(16px);
      }
    }
  }
  &__link {
    background: transparent;
    border: none;
    border-radius: size(6px);
    padding: size(12px);
    text-align: left;
    margin-bottom: size(5px);
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: #EDF3FC;
    }
    &.active {
      background: #EDF3FC;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    padding: size(8px) size(12px);
    border-radius: size(6px);

    .custom-toggle {
      padding: 0;
      margin-right: size(10px);
      label {
        color: $color-chambray;
      }
    }

    &:hover {
      background: #EDF3FC;
    }

    &.active {
      background: #EDF3FC;
    }
  }
}