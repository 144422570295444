@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';
.alt {
    position: absolute;
    max-width: 250px;
    width: max-content;
    left: size(7px);
    top: size(-20px);
    z-index: 1000;
    display: none;

    span {
        padding: 6px 8px;
        background: #EFF0F9;
        font-size: size(10px);
        border-radius: size(4px) size(4px) size(4px) 0;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 400;
        line-height: 12px;
        color: #435875;

        &:after {
            content: '';
            position: absolute;
            bottom: size(-5px);
            left: 0;
            display: flex;
            width: size(6px);
            height: size(5px);
            background: #EFF0F9;
            clip-path: polygon(0 0, 0 100%, 100% 0);
            border-radius: 0 0 0 size(3px);
        }
    }
    &.active {
        display: flex;
    }
    &.right {
        left: auto;
        right: size(7px);
        span {
            border-radius: size(4px) size(4px) 0 size(4px);
            &:after {
                left: auto;
                right: 0;
            }
        }
    }
    &.blue {
        span {
            background: #E1EDFF;
            &:after {
                background: #E1EDFF;
            }
        }
    }
    &.dark {
        span {
            background: #435875;
            color: #fff;
            &:after {
                background: #435875;
            }
        }
    }
}