@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.number-sort-modal {
  display: none;
  background: #fdfdff;
  border: size(1px) solid #EFF0F9;
  box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
  border-radius: size(10px);
  width: size(270px);
  padding: size(14px) size(10px);

  &.active {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: size(40px);
    right: 0;
    z-index: 2;
    &.left{
      right: auto;
      left: 0;
    }
  }

  &__choice {
    display: flex;
    margin-bottom: size(8px);
    .select__block {
      height: size(40px);
    }
    .select__content.active {
      top: size(45px);
      padding: size(8px);
      button {
        padding: size(8px);
      }
    }
    .custom-input {
      margin: 0 0 0 size(8px);
      label input {
        height: size(40px);
        padding: 0 size(22px);
      }
    }
  }
  &__btn {
    margin-bottom: size(8px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
}