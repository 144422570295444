@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.input-fieldset {
  margin: 0 0 size(22px);

  &.custom-input {
    padding: 0;
    display: block;
  }

  p {
    margin: 0 0 size(10px);
    padding: 0 size(15px);
  }

  .double-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;
    margin-bottom: size(22px);
  }

  &__wrapper {
    border: size(2px) solid $color-ghost-white-2;
    border-radius: 10px;
    padding: 0 size(12px);
  }

  &__users-list {
    margin: 0;
    padding: size(20px) 0 size(12px);
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__users-item {
    &:last-child {
      .tag {
        margin-right: 0;
      }
    }
  }

  &__btn {
    margin-bottom: size(22px);
  }

  &__access {
    display: none;
  }

  &.is-active {
    .input-fieldset__btn {
      display: none;
    }

    .input-fieldset__access {
      display: block;

      .custom-input {
        padding: 0;
      }
    }
  }
}
