@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.select-search {
  position: relative;
  width: 100%;
  &__label {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;
    span.red {
      color: #E56384;
    }
    .tooltip {
      margin: 0;
    }
  }

  &__block {

  }

  &__input {
    margin-bottom: 0;
  }

  &__list {
    display: none;
    position: absolute;
    top: 55px;
    background: #fdfdff;
    border: size(1px) solid #EFF0F9;
    box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    width: 100%;
    padding: size(14px) size(10px);
    left: 0;
    z-index: 100;
    max-height: size(340px);
    height: max-content;
    overflow-y: auto;
    &.active {
      display: flex;
      flex-direction: column;
      &.top {
        top: auto;
        bottom: size(55px);
      }
    }
  }
  &:has(&__label) {
    .select-search__list {
      top: size(80px);
      &.top {
        bottom: size(80px);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: transparent;
    border: none;
    border-radius: size(6px);
    padding: size(12px);
    text-align: left;
    margin-bottom: size(5px);
    cursor: pointer;
    color: $color-chambray;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: #EDF3FC;
    }
    &.active {
      background: #EDF3FC;
    }
  }
}