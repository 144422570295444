@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.select {
  width: 100%;
  position: relative;
  &__label {
    display: flex;
    align-items: center;
    font-size: size(16px);
    line-height: size(19px);
    color: #435875;
    margin-bottom: size(10px);
    gap: size(8px);

    .tooltip {
      margin: 0;
    }

    span.red {
      color: #E56384;
    }
  }
  &__block {
    width: 100%;
    height: size(48px);
    background: #EDF3FC;
    border-radius: size(10px);
    display: flex;
    align-items: center;
    padding-left: size(20px);
    padding-right: size(40px);
    color: #007BFF;
    position: relative;
    border: none;
    cursor: pointer;
    margin: 0;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(19px);
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: size(10px);
      height:size(1px);
      width: size(10px);
      background: #007BFF;
      transform: rotate(-45deg);
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: size(17px);
      height:size(1px);
      width: size(10px);
      background: #007BFF;
      transform: rotate(45deg);
    }
    &:hover {
      background: linear-gradient(0deg, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.05)), #EDF3FC;
    }
    &.off-select {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
    &.active {
      background: linear-gradient(0deg, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.05)), #EDF3FC;
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
    &.input {
      margin: 0;
      //color: #fff;
      //background: #007BFF;
      //&:after {
      //  background: #fff;
      //}
      //&:before {
      //  background: #fff;
      //}
    }
    & img {
      margin: 0 size(12px) 0 0;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__input {
    display: flex;
    align-items: center;
    &_field {
      background: #F9FAFF;
      border-radius: size(8px);
      width: size(43px);
      height: size(30px);
      outline: none;
      margin: 0 size(12px);
      text-align: center;
      color: #007BFF;
      border: size(1px) solid rgba(0, 123, 255, 0.4);
      &.error {
        border-color: $color-torch-red;
      }
      &.string {
        width: size(100px);
      }
      &.xs {
        height: size(26px);
      }
    }
  }
  &-inner {
    &__title {
      display: flex;
      align-items: center;
    }
    .select__block {
      height: size(38px);
      border-radius: size(8px);
      padding: 0 size(30px) 0 size(6px);
    }
  }
  &__content {
    display: none;
    background: #fdfdff;
    border: size(1px) solid #EFF0F9;
    box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    width: 100%;
    padding: size(14px) size(10px);
    &.active {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: size(55px);
      left: 0;
      z-index: 100;
      max-height: size(340px);
      height: max-content;
      overflow-y: auto;
      &.top {
        top: auto;
        bottom: size(55px);
      }
    }
    button {
      background: transparent;
      border: none;
      border-radius: size(6px);
      padding: size(12px);
      text-align: left;
      margin-bottom: size(5px);
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background: #EDF3FC;
      }
      &.active {
        background: #EDF3FC;
      }
    }
  }
  &__link {
    background: transparent;
    border: none;
    border-radius: size(6px);
    padding: size(12px);
    text-align: left;
    margin-bottom: size(5px);
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: #EDF3FC;
    }
    &.active {
      background: #EDF3FC;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    padding: size(8px) size(12px);
    border-radius: size(6px);

    .custom-toggle {
      padding: 0;
      margin-right: size(10px);
      label {
        color: $color-chambray;
      }
    }
    &_secondary {
      .custom-toggle {
        label {
          color: $color-rock-blue;
        }
      }
    }

    &:hover {
      background: #EDF3FC;
    }

    &.active {
      background: #EDF3FC;
    }
  }
  &__reset {
    margin-left: auto;
  }
  &--dark {
    .select__block {
      background: rgba(67, 88, 117, 0.05);
      color: $color-chambray;
      &:after {
        background: $color-chambray;
      }
      &:before {
        background: $color-chambray;
      }
    }
    &-text {
      .select__block {
        color: $color-chambray;
        &:after {
          background: $color-chambray;
        }
        &:before {
          background: $color-chambray;
        }
      }
    }
  }
  &--transparent {
    .select__block {
      background: transparent;
    }
  }
  &--label {
    .select__content.active {
      top: size(80px);
      &.top {
        top: auto;
        bottom: size(55px);
      }
    }
  }
  &--width-auto {
    width: max-content;
    max-width: 100%;
    .select {
      &__block {
        width: auto;
        max-width: 100%;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }
      }
      &__content {
        width: max-content;
        min-width: 100%;
      }
    }
  }
  &__search {
    display: flex;
    align-items: center;
    margin-bottom: size(5px);
    &_input {
      margin-bottom: 0;
      width: max-content;
    }
  }
  &__reset_icon {
    button.btn--icon {
      border-radius: size(10px);
      width: size(32px);
      height: size(32px);
      padding: size(6px);
      margin-left: size(6px);
      svg {
        width: size(16px);
        height: size(16px);
      }
    }
  }
  &--disabled {
    .select__block {
      pointer-events: none;
      cursor: default;
      opacity: .6;
    }
  }
  &--right {
    .select__content.active {
      left: auto;
      right: 0;
    }
  }
}