@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.reports {
  overflow: hidden;
  border-radius: size(12px) size(12px) 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
  }
  &__wrapper {
    flex-grow: 1;
    height: calc(100vh - #{size(200px)});
    display: flex;
    flex-direction: column;

    @include custom-scroll;
  }
  &__header {
    display: flex;
    .form-search {
      width: calc(100% - #{size(20px)});
      border-bottom: size(2px) solid #EFF0F9;
      & input {
        height: size(60px);
      }
      & input:hover,
      & input:focus, {
        background: transparent;
      }
    }
  }
  &__add {
    width: size(60px);
    border: none;
    background: transparent;
    border-bottom: size(2px) solid #EFF0F9;
    border-left: size(2px) solid #EFF0F9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      display: flex;
      width: size(20px);
      height: size(20px);
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: size(9px);
        left: 0;
        transform: rotate(90deg);
        width: size(20px);
        height: size(2px);
        background: #007BFF;
      }
      &:before {
        content: '';
        position: absolute;
        top: size(9px);
        left: 0;
        width: size(20px);
        height: size(2px);
        background: #007BFF;
      }
    }
  }
}

.reports-table {
    font-size: size(14px);
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__wrapper {
      width: 100%;
      max-height: calc(100vh - #{size(120px)} - #{size(60px)});
      overflow-y: auto;
      flex-grow: 1;
    }
    &-head {
      display: grid;
      grid-template-columns: 32% 17% 12% 17% 12% 10%;
      border-bottom: size(2px) solid rgba(67, 88, 117, 0.08);
      &__item {
        padding: size(16px) size(22px);
        font-weight: 500;
        font-size: size(14px);
        color: #8E9EB5;
        background: transparent;
        border: none;
        outline: none;
        &:first-child {
          padding-left: size(40px);
        }
      }
      &__title {
        display: flex;
        align-items: center;
        width: max-content;
      }
    }
    &-body {
      overflow-y: auto;
      flex-grow: 1;
      &__item {
        width: 100%;
        position: relative;
        &:hover {
          background-color: #edf3fc;
        }
        //&:after {
        //  content: "";
        //  position: absolute;
        //  top: size(25px);
        //  left: size(19px);
        //  background-color: #8E9EB5;
        //  width: size(10px);
        //  height: size(1px);
        //  display: block;
        //  float: right;
        //  border-radius: size(10px) size(6px) size(6px) size(10px);
        //  //transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
        //  transform: rotate(-45deg);
        //}
        //&:before {
        //  content: "";
        //  position: absolute;
        //  top: size(25px);
        //  left: size(12px);
        //  background-color: #8E9EB5;
        //  width: size(10px);
        //  height: size(1px);
        //  display: block;
        //  float: right;
        //  border-radius: size(6px) size(10px) size(10px) size(6px);
        //  //transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
        //  transform: rotate(45deg);
        //}
        &.active {
          &:after {
            background-color: #435875;
            transform: rotate(-135deg);
          }
          &:before {
            background-color: #435875;
            transform: rotate(135deg);
          }
          .reports-table-body__head {
            background: rgba(67, 88, 117, 0.02);
          }
        }
      }
      &__head {
        //display: flex;
        align-items: center;
        display: grid;
        grid-template-columns: 32% 17% 12% 17% 12% 10%;
        border: none;
        border-bottom: size(2px) solid rgba(67, 88, 117, 0.08);
        height: size(51px);
        background: transparent;
        width: 100%;
        text-align: left;
        margin: 0;
        padding: 0;
        cursor: pointer;
        & > div {
          padding: 0 size(22px);
          &:not(.reports-table-body__action) {
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:first-child {
            padding-left: size(40px);
          }
        }
      }
      &-info {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: size(22px) size(40px) size(12px);

        background: rgba(67, 88, 117, 0.02);
        border-bottom: size(2px) solid rgba(67, 88, 117, 0.08);
        &__item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: size(8px) size(12px);
          gap: size(2px);
          margin-right: size(10px);
          margin-bottom: size(10px);

          border: size(1px) solid rgba(67, 88, 117, 0.12);
          border-radius: size(6px);
          &:last-child {
            margin-right: 0;
          }
        }
        &__title {
          font-weight: 400;
          font-size: size(12px);
          line-height: size(15px);
          text-transform: uppercase;
          color: #8390A4;
        }
        &__text {
          font-weight: 400;
          font-size: size(14px);
          line-height: size(17px);
          color: #435875;
          margin: 0;
          padding: 0;
        }
      }
      &__action {
        display: flex;
        align-items: center;
        .btn--icon {
          width: size(38px);
          height: size(38px);
          padding: 0;
          background-color: $color-ghost-white;
        }
      }
    }

    .custom-input {
      //width: 300px;
      input {
        background: #fff;
        border: none;
        font-weight: 500;
        &:hover,
        &:focus {
          background: #fff;
        }
        &::placeholder {
          font-weight: 500;
        }
      }
    }
    th {
      font-weight: 500;
      font-size: size(14px);
      color: #8E9EB5;
      border-bottom: size(2px) solid rgba(67, 88, 117, 0.08);
      padding: size(20px) size(6px);
      cursor: pointer;
      &:first-child {
        padding-left: size(23px);
      }
      span {
        margin-right: size(4px);
      }
      &:hover {
        svg path {
          fill: #8E9EB5;
        }
      }
      &.ascending {
        svg path {
          fill: #007BFF;
        }
      }
      &.descending {
        svg {
          transform: rotate(180deg);
          path {
            fill: #007BFF;
          }
        }
      }
    }
    td {
      border-bottom: size(1px) solid rgba(67, 88, 117, 0.08);
      color: #435875;
      font-size: size(14px);
      padding: size(17px) size(6px);
      font-weight: 400;
      line-height: size(17px);
      transition: .3s linear;
      &:first-child {
        padding-left: size(23px);
      }
      &.red {
        padding: size(12px) size(8px);
        span {
          background: rgba(229, 99, 132, 0.2);
          border-radius: size(10px);
          width: max-content;
          padding: 0 size(10px);
          height: size(24px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  &__btn {
    background: rgba(67, 88, 117, 0.05);
    border-radius: size(6px);
    width: size(29px);
    height: size(29px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    img {
      height: size(15px);
      margin: 0;
      padding: 0;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: size(20px) 0;
    flex-grow: 1;
    img {
      width: size(80px);
      margin-bottom: size(12px);
    }
    span {
      font-weight: 500;
      font-size: size(14px);
      color: #8E9EB5;
    }
  }


  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 size(22px);
    min-height: size(50px);
    height: size(50px);
    border-top: size(1px) solid rgba(67, 88, 117, 0.08);
    margin-top: auto;
  }
  &-count {
    display: flex;
    align-items: center;
    span {
      color: #8E9EB5;
      font-size: size(14px);
      margin-right: size(14px);
    }
    select {
      background: transparent;
      border: none;
    }
    &__select {
      position: relative;
      width: size(45px);
      &_block {
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        color: #435875;
        border: none;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          height: size(1px);
          width: size(10px);
          background: #8E9EB5;
          transform: rotate(-45deg);
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: size(7px);
          height: size(1px);
          width: size(10px);
          background: #8E9EB5;
          transform: rotate(45deg);
        }
        &.active {
          &:after {
            transform: rotate(45deg);
          }
          &:before {
            transform: rotate(-45deg);
          }
        }
        & img {
          margin: 0 size(12px) 0 0;
        }
      }
      &_content {
        display: none;
        background: #F9FAFF;
        border: size(1px) solid #EFF0F9;
        box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
        border-radius: size(10px);
        width: 100%;
        padding: size(5px);
        &.active {
          position: absolute;
          display: flex;
          flex-direction: column;
          bottom: size(22px);
          left: 0;
          z-index: 1;
        }
        button {
          background: transparent;
          border: none;
          border-radius: size(6px);
          text-align: left;
          margin-bottom: size(5px);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: size(4px) size(2px);
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #EDF3FC;
          }
          &.active {
            background: #EDF3FC;
          }
        }
      }
    }
  }
  &-paginator {
    display: flex;
    align-items: center;
    &__arrow {
      width: size(32px);
      height: size(32px);
      border: size(1px) solid rgba(67, 88, 117, 0.08);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 size(18px);
      background: transparent;
      cursor: pointer;
      &:hover {
        border-color: transparent;
        background: #EDF3FC;
        svg {
          color: #435875;
        }
      }
      &:last-child {
        margin-right: 0;
      }

    }
    &__pages {
      display: flex;
      align-items: center;
      li {
        margin-right: size(2px);
        &.active button {
          color: #007BFF;
        }
      }
    }
    &__page {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: size(14px);
      color: #435875;
      border: none;
      padding: 0 size(10px);
      height: size(28px);
      cursor: pointer;
      background: transparent;
      &:hover {
        background: #EDF3FC;
        border-radius: size(4px);
      }
    }
    .more {
      background: rgba(67, 88, 117, 0.03);
      border: size(1px) solid rgba(67, 88, 117, 0.08);
      border-radius: size(4px);
      font-size: size(14px);
      color: #435875;
      width: size(50px);
      height: size(32px);
      text-align: center;
      outline: none;
      &:focus {
        border-color: #007BFF;
      }
    }
  }
}