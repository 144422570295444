@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.header {
  display: flex;
  align-items: center;

  min-height: size(90px);
  padding: size(18px) 0;

  background-color: $color-ghost-white;
  transition: transform .1s linear;

  &.hide {
    transform: translateY(size(-90px));
  }

  .container {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: inline-flex;
    align-items: center;

    margin-right: size(40px);
    color: $color-default-white;
    outline: none;
    transition: opacity $trans-default;

    &[href] {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 1;
        }
      }
    }
  }

  .breadcrumbs {
    max-width: 70%;
    margin-right: size(40px);
  }

  &__btn-wrapper {
    width: 100%;
    max-width: size(606px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: size(12px) size(40px);
  }

  &__dashboard-btn {
    max-width: fit-content;

    svg {
      width: size(14px);
      height: size(14px);
    }
  }

  &__docs {
    margin-left: auto;
    //height: 14px;
    //border: size(1px) solid $color-dodger-blue;
    //border-radius: 50%;
    //padding: size(4px);
    //width: size(25px);
    //height: size(25px);
  }

  .user-menu {
    //min-width: size(220px);
    margin-left: auto;
  }
  &__docs:not(:empty) ~ .user-menu {
    margin-left: size(20px);
  }

  .btn--icon {
    background-color: $color-transparent;
  }
}
