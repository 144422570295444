@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.note {
    display: flex;
    border-left: 2px solid $color-dodger-blue;
    padding-left: 10px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: size(10px) 0;
        max-width: 100%;

        p {
            font-size: size(14px);
            margin: 0;
            padding: 0;
            a {
                font-size: size(14px);
                cursor: pointer;
                color: $color-dodger-blue;
                &:hover {
                  text-decoration:underline;
                }
            }
        }
    }
}