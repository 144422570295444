@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';
.Integration-card {
  max-width: size(146px);
  min-height: 146px;
  background: rgba(237, 243, 252, 1);
  border-radius: 12px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 8px;
  cursor: pointer;
  position: relative;
  &:hover {
    border-color: #007CFF;
    .Integration-card__btn {
      display: flex;
    }
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__name {
    color: #8e9eb5;
    font-size: size(10px);
    text-align: center;
    margin: 0 size(8px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: size(130px);
  }
  &__btn {
    border: none;
    background: #E5EEFC;
    border-radius: 10px;
    width: 32px;
    height: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;

    &.edit {
      position: absolute;
      top: size(4px);
      left: size(4px);
    }
    &.remove {
      position: absolute;
      top: size(4px);
      right: size(4px);
    }
    img {
      height: 15px;
      margin: 0;
    }
  }
  &__remove {

  }
}