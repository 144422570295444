@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';
@import '../../ui/Toggle/Toggle.scss';

.dropdown-list {
  width: 100%;
  min-width: size(320px);
  position: absolute;

  bottom: size(-4px);
  right: size(-35px);
  padding: size(20px);

  background: $color-ghost-white;
  box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
  border: 2px solid $color-ghost-white-2;
  border-radius: size(12px);

  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  z-index: 2;
  transition: visibility $trans-default, opacity $trans-default, height $trans-default, min-height $trans-default;
}

.dropdown-list__list {
  margin: 0;
  padding: 0;
  list-style: none;

  .btn {
    padding-top: size(11px);
    padding-bottom: size(11px);
  }
}

.dropdown-list__add-btn {
  margin-top: size(8px);
}

.dropdown-list__item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: size(42px);

  border: 0;
  border-radius: size(10px);
  outline: 0;
  transition: background-color $trans-default;
  overflow: hidden;
  cursor: pointer;
}

.dropdown-list__item:not(:last-child) {
  margin-bottom: size(12px);
}

.dropdown-list__title {
  display: flex;
  flex-grow: 1;
  min-width: 0;

  font-family: $ff-inter;
  font-weight: 400;
  font-size: size(14px);
  line-height: size(17px);
  color: $color-chambray;
}

.dropdown-list__right {
  display: flex;
  margin-left: size(10px);
}
