@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.info-card {
  border: 2px solid $color-ghost-white-2;
  border-radius: 12px;

  &__header {
    padding: size(15px) size(22px) size(14px);

    font-size: size(16px);
    line-height: size(16px);
    text-transform: uppercase;
    color: $color-ship-cove;

    border-bottom: 2px solid $color-ghost-white-2;
  }

  &__content {
    padding: size(10px) size(22px);
  }
}