.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px 0;
  width: 100%;
  &__img {
    width: 80px;
    margin-bottom: 12px;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    color: #8E9EB5;
    margin-bottom: 12px;
  }
}