// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "";

//   font-display: swap;
//   src:
//     url("../fonts/.woff2") format("woff2"),
//     url("../fonts/.woff") format("woff");
// }

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Regular.woff") format("woff2"),
    url("../../public/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Medium.woff2") format("woff2"),
    url("../../public/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url("../../public/fonts/AvertaCY-Regular.woff2") format("woff2"),
    url("../../public/fonts/AvertaCY-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url("../../public/fonts/AvertaCY-Light.woff2") format("woff2"),
    url("../../public/fonts/AvertaCY-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
