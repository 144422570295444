@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.filter-dashboards {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__body {
    flex-grow: 1;
  }
  &__add {
    height: size(44px);
    font-weight: 500;
    font-size: size(14px);
    button {
      padding: 0 size(22px);
      height: 100%;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: size(12px);
    width: 100%;
    &__choice {
      margin-right: size(8px);
      margin-bottom: size(8px);
      max-width: 100%;
      position: relative;
      &:last-child{
        margin-right: 0;
      }
      &_input {
        margin: 0;
        width: size(160px);
      }
      &_loader {
        background: rgba(67, 88, 117, 0.05);
        height: size(48px);
        border-radius: size(10px);
        width: size(160px);
      }
      .select__search_input {
        width: 100%;
      }
    }
    &__content_long {
      .select__content {
        max-width: size(400px);
        .custom-toggle__label {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: anywhere;
        }
      }
    }
    &__remove {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      cursor: pointer;
      margin: 0 0 size(8px) 0;
      padding: 0;
      img {
        margin: 0;
      }
    }
  }
  &__btn {
    width: 100%;
    display: flex;
    button{
      &:first-child {
        flex-grow: 2;
      }
      &:nth-child(2) {
        flex-grow: 1;
        margin-left: size(12px);
      }
    }
  }
}