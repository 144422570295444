@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/mixins';

.form {
  height: 100%;
  padding: 0 size(5px);

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;
    background-color: $color-ghost-white;

    &.form__buttons--one-col {
      grid-template-columns: 1fr;
    }
  }
  &__wrapper {
    .select__search_input {
      width: 100%;
    }
  }
}

.form--in-auth {
  padding: 0;

  .form__buttons {
    margin-top: size(40px);
  }
  .form__checkbox {
    padding: 0;
  }
  .form__recover {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    margin: 40px 0 0 auto;
    font-size: 22px;
    color: $color-rock-blue;
    border: none;
    background: transparent;
    cursor: pointer;
    &:hover {
      color: $color-dodger-blue;
    }
  }
  .custom-toggle__label {
    font-size: size(11px);
  }
}
