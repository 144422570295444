@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.pagination {
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 size(22px);
    height: size(50px);
    border-top: size(1px) solid rgba(67, 88, 117, 0.08);
  }
  &-count {
    display: flex;
    align-items: center;
    span {
      color: #8E9EB5;
      font-size: size(14px);
      margin-right: size(14px);
    }
    select {
      background: transparent;
      border: none;
    }
    &__select {
      position: relative;
      width: size(45px);
    }
  }
  &-paginator {
    display: flex;
    align-items: center;
    margin-left: auto;
    &__arrow {
      width: size(32px);
      height: size(32px);
      border: size(1px) solid rgba(67, 88, 117, 0.08);
      border-radius: size(4px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 size(18px);
      background: transparent;
      cursor: pointer;
      &:hover {
        border-color: transparent;
        background: #EDF3FC;
        svg {
          color: #435875;
        }
      }
      &:last-child {
        margin-right: 0;
      }

    }
    &__pages {
      display: flex;
      align-items: center;
      li {
        margin-right: size(2px);
        &.active button {
          color: #007BFF;
        }
      }
    }
    &__page {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: size(14px);
      color: #435875;
      border: none;
      padding: 0 size(10px);
      height: size(28px);
      cursor: pointer;
      background: transparent;
      &:hover {
        background: #EDF3FC;
        border-radius: size(4px);
      }
    }
    .more {
      background: rgba(67, 88, 117, 0.03);
      border: size(1px) solid rgba(67, 88, 117, 0.08);
      border-radius: size(4px);
      font-size: size(14px);
      color: #435875;
      width: size(50px);
      height: size(32px);
      text-align: center;
      outline: none;
      &:focus {
        border-color: #007BFF;
      }
    }
  }
}

.pagination_select {
  .select {
    &__block {
      width: 100%;
      background: transparent;
      display: flex;
      align-items: center;
      color: #435875;
      border: none;
      cursor: pointer;
      padding-left: 0;
      padding-right: size(10px);
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        height: size(1px);
        width: size(10px);
        background: #8E9EB5;
        transform: rotate(-45deg);
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: size(7px);
        height: size(1px);
        width: size(10px);
        background: #8E9EB5;
        transform: rotate(45deg);
      }
      &.active {
        &:after {
          transform: rotate(45deg);
        }
        &:before {
          transform: rotate(-45deg);
        }
      }
    }
    &__content {
      padding: size(5px);
      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.active {
        top: size(45px);
        &.top {
          top: auto;
        }
      }
    }
  }
}