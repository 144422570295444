@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.tabs {
  &__controls {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }

  &__control-item {
    margin-right: size(20px);

    &.is-active {
      .tabs__control {
        color: $color-dodger-blue;

        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__control.btn {
    position: relative;
    padding: 0;
    min-height: auto;
    width: fit-content;
    border-radius: 0;
    color: $color-chambray;


    &::before {
      position: absolute;
      content: "";
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color-dodger-blue;
      opacity: 0;
      transition: opacity $trans-default;
    }

    span {
      font-size: size(22px);
      padding: size(7px) 0;
      font-family: $ff-averta;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-transparent;
      }

      &:focus {
        background-color: $color-transparent;
      }
    }
  }
}
