@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.settings-roles {
  padding: size(40px) size(40px) size(20px) size(40px);

  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: size(20px);
  }

  &__title {
    font-size: size(18px);
    color: #435875;
    margin-top: 0;
    margin-bottom: size(10px);
  }

  &__description {
    font-size: size(14px);
    line-height: size(17px);
    color: #8E9EB5;
    margin: 0;
  }

  &__table {
    &-head {
      width: 800px;
      border: 2px solid #EFF0F9;
      border-bottom: none;
      border-radius: 10px 10px 0 0;
    }
    &-scroll {
      width: 800px;
      overflow-y: auto;
      max-height: 300px;
      border-radius: 0 0 10px 10px;
      border: 2px solid #EFF0F9;
      border-top: none;
    }

    width: 100%;
    //padding: 10px 5px;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    thead {
      background: #EFF0F9;
      tr th {
        border: none;
        text-align: left;
        padding: 10px 15px;
        font-size: size(14px);
        color: #8E9EB5;
      }
    }
    tbody tr td {
      //display: flex;
      //align-items: center;
      padding: 10px 15px;
      font-size: size(14px);
      color: #435875;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    &:hover .settings-api__copy {
      opacity: 1;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__name {
    color: #435875;
    font-size: size(14px);
    margin-bottom: size(2px);
  }
  &__token {
    font-size: size(12px);
    color: #435875;
    opacity: 0.6;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__copy {
    border: none;
    background: transparent;
    cursor: pointer;
    opacity: 0;
    img {
      width: 14px;
      margin: 0;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      border: none;
      background: transparent;
      cursor: pointer;
      img {
        height: 20px;
        margin: 0;
      }
    }
  }
  &__btn {
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      height: 20px;
      margin: 0;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    margin-top: 20px;
  }
}