.edit-select-modal {
  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    button {
      width: 49%;
    }
  }
}