@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.code {
  background: $color-ghost-white-2;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;

  pre {
    margin: 0;
    overflow-x: auto;
    @include custom-scroll;
  }
  svg {
    display: none;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 10px;
    right: 7px;
    cursor: pointer;
  }

  &__copy-btn {
    &_copied {
      background: #F9FAFF;
      border: size(2px) solid #EFF0F9;
      color: #435875;
      &:hover, &:focus, &::before {
        background: #F9FAFF;
        color: #435875;
      }
    }
  }
  
  &:hover {
    svg {
      display: block;
    }
  }

  &--width-auto {
    width: max-content;
  }
  &--text {
    &-size-s {
      pre {
        font-size: size(14px);
      }
    }
  }
  &--extend {
    display: flex;
    flex-direction: column;
    background: #EDF3FC;
    border-radius: size(10px);
    padding: size(22px);
    .code {
      &__container {
        background: #F9FAFF;
        border: size(2px) solid #EFF0F9;
        border-radius: size(10px);
        padding: size(20px) size(12px);
        margin-bottom: size(12px);
        color: #435875;
      }
    }
  }
}