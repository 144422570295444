@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.integrations-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.integrations-modal__wrapper {
  width: 470px;

  background: #F9FAFF;
  border: 2px solid #EFF0F9;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}
.integrations-modal__head {
  padding: 20px 30px;
  border-bottom: 2px solid #EFF0F9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}