@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.new-placement {
  height: calc(100% - #{size(70px)});
  &__wrapper {
    padding: size(8px) size(12px) size(22px) size(15px);
    height: 100%;
    overflow-y: auto;

    /*Кастомный скроллбар*/

    @include custom-scroll;
  }
  &__url {
    margin-bottom: size(5px);
    &.disabled {
      margin-bottom: size(20px);
    }
  }
  &__checkbox {
    margin-bottom: size(20px);
  }
  &__btns {
    display: flex;
    align-items: center;
    padding: 0 size(15px);
    button {
      width: 50%;
    }
  }
  .tooltip__alt {
    max-width: size(220px);
  }
}