@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.multi-actions {
  position: absolute;
  bottom: size(48px);
  left: size(20px);
  width: calc(100% - 40px);
  padding: 0 size(20px);
  height: size(60px);
  //background: #edf3fc;
  background: #E1EDFF;
  //background: #007bff;
  //color: white;
  border: size(1px) solid #EFF0F9;
  border-radius: size(10px);
  display: flex;
  align-items: center;

  &__btns {
    margin-left: auto;
    display: flex;
    align-items: center;
    //gap: size(20px);
  }
  &__btn {
    border: none;
  }

  &__reset {
    margin-left: 20px;
  }
}