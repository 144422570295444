@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.profile-panel {
  display: flex;
  justify-content: space-between;
  padding: size(40px);

  overflow: auto;

  &__left {
    flex: 1;
    margin-right: size(40px);
  }

  &__exit-btn {
    display: flex;
    width: size(240px);
    margin-top: size(53px);
    margin-left: auto;
  }

  &__right {
    flex: 1;
  }
}

