.yandex-direct {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  &__wrapper {
    width: 400px;
  }
  &__title {
    margin-bottom: 10px;
    display: flex;
  }
  &__select {
    margin-bottom: 20px;
    position: relative;
    &-item {
      &__name {
        font-size: 14px;
        color: #435875;
        margin-bottom: 2px;
      }
      &__login {
        color: #7D8DA4;
        font-size: 12px;
      }
    }
  }
  &__content {
    display: none;
    background: #F9FAFF;
    border: 1px solid #EFF0F9;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 100%;
    padding: 14px 10px;
    max-height: 250px;
    overflow-y: auto;

    &.active {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 75px;
      left: 0;
      z-index: 2;

      &.top {
        top: auto;
        bottom: 55px;
      }
    }

    button {
      background: transparent;
      border: none;
      border-radius: 6px;
      padding: 12px;
      text-align: left;
      margin-bottom: 5px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #EDF3FC;
      }

      &.active {
        background: #EDF3FC;
      }
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    img {
      width: 80px;
      margin-bottom: 12px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: #8E9EB5;
    }
  }
}