.content {
  padding: 32px 16px 20px;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}

.select_wrapper {
  height: 46px;
  position: relative;
  border-radius: 10px;
  background: #ECF3FD;
  margin-left: 40px;
  display: none;
}

.select_arrow {
  content: '';
  position: absolute;
  top: 20px;
  right: 28px;
  bottom: 17px;
  border: 4px solid transparent;
  border-bottom: 0;
}

.select_wrapper select {
  width: 142px;
  height: 46px;
  background: transparent;
  border: 1px solid #007DFF;
  border-radius: 10px;
  padding-left: 28px;
  padding-right: 28px;
  color: #007DFF;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  appearance: none;
  text-align: center;
  cursor: pointer;
}

.hide_button {
  display: none;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 46px;
  margin-left: 40px;
  cursor: pointer;

  border: 1px solid #007DFF;
  background: none;
  color: #007DFF;
  border-radius: 10px;
  cursor: pointer;
}

.hide_input {
  display: none;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 46px;
  margin-left: 40px;
}

.show_input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 46px;
  margin-left: 40px;
  padding: 14px 28px;

  border: 1px solid #007DFF;
  background: none;
  border-radius: 10px;
}

.btn_ok {
  width: 46px;
  height: 46px;
  background: #007DFF;
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.input_entity {
  display: flex;
  margin-bottom: 2px;
  border-radius: 8px;
  height: 66px;
  align-items: center;
  width: fit-content;
  padding: 0 16px;
  &.active {
    background: #EAF3FD;
    align-content: flex-start;
      > .select_wrapper {
          display: flex;
        &:hover {
          opacity: 0.7;
          display: flex;
        }
      }
      > .hide_button {
        display: flex;
        background: none;
        &:hover {
          opacity: 0.7;
        }
      }
      > .change_name_btn {
        display: none;
      }
  }
  &:hover {
    background: #EAF3FD;
    align-content: flex-start;
      > .select_wrapper {
          display: flex;
        &:hover {
          display: flex;
        }
      }
      > .hide_button {
        display: flex;
        background: none;
        &:hover {
          opacity: 0.7;
        }
      }
      > .change_name_btn {
        display: none;
      }
    }
}

.entity {
  display: flex;
  margin-bottom: 2px;
  border-radius: 8px;
  height: 66px;
  align-items: center;
  width: fit-content;
  padding: 0 16px;
}

.changing_entity {
  display: flex;
  margin-bottom: 2px;
  border-radius: 8px;
  height: 66px;
  align-items: center;
  width: fit-content;
  padding: 0 16px;
  background: #EAF3FD;
  align-content: flex-start;
}

.info_wrapper {
  display: flex;
  flex-direction: column;
  float: left;
}

.change_name_btn {
  border: 1px solid #007DFF;
  background: none;
  color: #007DFF;
  border-radius: 12px;
  cursor: pointer;
}

label {
  margin-bottom: 2px;
  color: rgba(142, 158, 181, 1);
}

.switch {
  position: relative;
  display: inline-block;
  width: 38px;   // 60px;
  height: 20px;   // 34px;
  padding: 0;
  margin-top: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0.3px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.submit_btn {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  max-height: 51px;
  border: none;
  border-radius: 10px;
  background: #ECF3FD;
  color: rgba(0, 123, 255, 1);
  margin-top: 40px;
  cursor: pointer;
  white-space: nowrap;
}

.submit_btn_disactive {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  max-height: 51px;
  border: none;
  border-radius: 10px;
  background: #F0F2F9;
  color: #627791;
  margin-top: 40px;
  pointer-events: none;
  white-space: nowrap;
}

.delete_btn {
  background: none;
  border: none;
  color: #F65883;
  cursor: pointer;
  position: absolute;
  right: 80px;
  &:hover {
    opacity: 0.8;
  }
}