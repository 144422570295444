@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';
.time-filter {
  position: relative;
  width: 100%;
  .select__content {
    &.active {
      max-height: size(452px);
    }
  }
}

.custom-input__rdp {
    position: absolute;
    top: size(65px);
    left: size(8px);
    z-index: 2;

    padding-bottom: 20px;

    // background-color: $color-default-white;
    // border-radius: size(4px);
    // box-shadow:
    //         0 size(1px) size(10px) rgb(0 0 0 / 4%),
    //         0 size(4px) size(5px) rgb(0 0 0 / 6%),
    //         0 size(2px) size(4px) size(-1px) rgb(0 0 0 / 9%);
    animation-name: fade-in;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
    //overflow: hidden;
  }

  .custom-input__rdp-inner {
    padding-bottom: size(1px);
    background-color: $color-default-white;
    margin-top: size(-24px);
    border-radius: size(12px);
    box-shadow: 0 0 size(12px) rgb(0 0 0 / 8%);
    border: size(1px) solid #EAEDF4;
  }

  .rdp-caption {
    padding-bottom: size(8px);
  }
  .rdp-caption_label {
    font-size: size(22px);
    font-weight: 500;
    color: #007BFF;
  }
  .rdp-table {
    color: #435875;
  }