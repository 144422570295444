@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.limit-traffic-tab {
  display: flex;
  align-items: center;
  border: 2px solid #EFF0F9;
  border-radius: 10px;
  height: 52px;
  width: 100%;
  margin-bottom: 22px;
  &__btn {
    color: #007BFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background: #f9faff;
    border: none;
    cursor: pointer;

    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    &.active {
      background: #EDF3FC;
    }
  }
}

.input_section {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  background: #ECF3FD;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.input_error {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  background: #EAC5CF;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
}

.select_wrapper {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  position: relative;
  border-radius: 10px;
  background: #ECF3FD;
  &:hover {
    box-shadow: 0 0 3px gray;
  }
}

.select_arrow {
  content: '';
  position: absolute;
  top: 20px;
  right: 28px;
  bottom: 17px;
  border: 4px solid transparent;
  border-top-color: #6C7231;
  border-bottom: 0;
}

.select_wrapper select {
  width: 410px;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  padding-right: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  appearance: none;
}

.limit-traffic-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  button {
    width: 49%;
  }
}