@import '../../styles/variables';
@import '../../styles/functions';

.dashboard-list {
  margin: 0 0 size(28px);
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: size(18px);

  &__item {
    min-width: size(319px);
  }
}
