@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.setting {
  &__select-wrapper {
    .select {
      &__block {
        padding-right: 40px;
        border: 1px solid #007BFF;
      }

      &__content {
        overflow-y: auto;
        max-height: 270px;
      }
    }
  }
  &__btns {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}