@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.access-time{
  &__block {
    margin-bottom: 30px;
    & .custom-input {
      margin: 0;
      & svg {
        top: 8px;
      }
      & input {
        height: 41px;
      }
    }
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
      width: 49%;
    }
  }
}


