@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.message-popup {
  position: fixed;
  bottom: size(22px);
  left: size(22px);
  z-index: 10000;

  display: flex;
  align-items: center;
  padding: size(26px) size(16px);

  background-color: $color-alice-blue;
  border-radius: size(12px);

  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  overflow: hidden;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: size(40px);
    height: size(40px);
    margin-right: size(16px);

    background:
            linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
            $color-alice-blue;
    border-radius: size(10px);

    svg {
      display: block;
      transform: translate3d(0, 0, 0);
    }
  }

  &__wrapper {
    margin-right: size(16px);
  }

  &__title {
    font-weight: 500;
    font-size: size(16px);
    line-height: 140%;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: size(14px);
    line-height: 140%;
  }

  &__close {
    padding: size(11px);
    color: $color-rock-blue;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-solitude-2;
      }

      &:active {
        background-color: $color-solitude-2;
      }
    }

    svg {
      display: block;
      width: size(10px);
      height: size(10px);
    }
  }

  &.is-error {
    background-color: $color-pink-lace;

    .message-popup__icon {
      background: rgba(0, 0, 0, 0.05);
    }

    .message-popup__close {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &.is-close {
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
