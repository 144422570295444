@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.message-modal {
  &__text {
    padding: 0 10%;
    margin: 0 0 size(10px);
    text-align: center;
    font-size: size(18px);
    line-height: size(22px);
    &:last-child {
      margin-bottom: size(30px);
    }
  }

  &__info {
    word-wrap: anywhere;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;
    background-color: $color-ghost-white;

    &.message-modal__buttons--one-col {
      grid-template-columns: 1fr;
    }
  }
  &__errors {
    margin-bottom: size(30px);
    max-height: 300px;
    overflow-y: auto;
    span {
      font-size: size(18px);
    }
  }
  &__error {
    font-size: size(14px);
    color: $color-warning;
  }
  &-errors {
    &__item {
      p {
        margin: 0;
        color: $color-torch-red;
        font-weight: 500;
      }
      ul {

      }
      li {
        font-size: size(14px);
        color: $color-torch-red;
        //color: $color-warning;
        margin-left: 20px;
      }
    }
  }
  .red {
    color: $color-torch-red;
  }
  .warning {
    color: $color-warning;
  }
}
