@import '../../styles/variables';
@import '../../styles/functions';

.dashboard {
  padding: 0;

  &__chart-wrapper {
    padding: size(13px) 0;
    border: 2px solid $color-ghost-white-2;
    border-radius: 12px 12px 0 0;

    .title {
      padding: 0 size(20px) size(12px);
      margin-bottom: size(20px);
    }
  }
}
