@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/functions.scss';
@import '../../../../../../styles/mixins.scss';
.dashboard {
  &-table {
    height: 100%;
    &-loader {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: size(300px);
      position: relative;
      margin-bottom: size(12px);
    }
  }
}