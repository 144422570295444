@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/functions.scss';
@import '../../../../../../styles/mixins.scss';
.charts {
  //position: relative;
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    ul {
      flex-wrap: wrap;
      li {
        margin-bottom: 5px;
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 12px;
          max-width: 130px;
          margin: 0;
          padding: 0;
        }
      }
    }
    &.short {
      ul {
        width: 100%;
        justify-content: center;
        li {
          max-width: calc((100% - 30px) / 3);
        }
      }
    }
  }

  &-full {
    position: absolute;
    top: 10px;
    right: 10px;
    width: size(32px);
    height: size(32px);
    &__btn {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  &-popup {
    position: absolute;
    top: size(35px);
    right: size(-5px);
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: size(6px);
    background: #fdfdff;
    //border: size(1px) solid #EFF0F9;
    box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    padding: size(5px);

    &__btn {
      padding: size(5px);
      width: size(32px);
      height: size(32px);
    }
  }
}
