@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';
.wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
}

.logo {
  width: 155px;
  height: 40px;
  flex-shrink: 0;
  margin-right: auto;
}

.form {
  width: 72%;
  margin-right: 12px;
}

.integratons_hidden {
  width: 50vw;
}

.integrations {
  width: 50%;
  border-width: 0 0 0 2px;
  border-color: #EFF0FA;
  border-style: solid;
}

.onboard_step {
  margin: 60px 0 12px 0;
  color: rgba(67, 88, 117, 1);
  font-size: 22px;
  line-height: 26.63px;
  font-weight: 400;
}

.onboard_desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #435875;
  padding: 0;
  margin: 0;
  max-width: 760px;
}

.add_btn {
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 146px;
  height: 146px;
  background: #ECF3FD;
  color: #007CFF;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
  &:before {
    content: '+';
    font-size: 32px;
  }
}

.modal_title {
  border-width: 0 0 2px 0;
  border-color: #EFF0FA;
  border-style: solid;
  margin: 0;
  padding: 20px 0 20px 28px;
  color: rgba(125, 141, 164, 1);
  line-height: 19px;
  text-transform: uppercase;
}

.options {
  margin: 22px 4px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

figure {
  margin: 0;
}

figcaption {
  text-align: center;
}
.search_block {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.search_input {
  width: 100%;
  //margin: 0 auto;
  margin-left: 7px;
  //height: 42px;
  border: none;
  //border-radius: 10px;
  //background: #EAF3FD;
  color: #899FB7;
  //padding: 12px 28px;
}

.nails {
  max-width: 462px;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-around;  // for big screens
  color: #607892;
  margin-top: 10px;
  //overflow: hidden;
  //overflow-x: scroll;
  gap: 8px;
  margin-left: 4px;
  padding: 0 2px;
}

.nail {
  display: inline-block;
  background: #EAF3FD;
  border-radius: 10px;
  padding: 8px 12px;
  color: rgba(67, 88, 117, 0.8);
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  border: none;
  white-space:nowrap;
  cursor: pointer;
  &.active {
    border: 1px solid $color-dodger-blue;
    color: $color-dodger-blue;
  }
}

.integration_card {
  max-width: 146px;
  height: 146px;
  background: rgba(237, 243, 252, 1);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 8px;
}

@mixin grid($countColumns, $margin) {
  margin-right: $margin;
  margin-bottom: $margin;
  height: 146px;
  width: ((100% - (($countColumns - 1 ) * $margin)) / $countColumns);

  &:nth-child(#{ $countColumns }n){
    margin-right: 0;
  }

}

.grid {
  display: flex;
  flex-wrap: wrap;
  > div {
    float: left;
    @include grid(3, 0.1%);
    //flex: 1 1 146px;
  }
}

figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//.card_img {
//  margin-bottom: 8px;
//}

img {
  margin-bottom: 8px;
}

.footer {
  margin-top: 32px;
  display: flex;
  align-items: center;
}

.skipper {
  border: none;
  background: none;
  cursor: pointer;
  color: rgba(125, 141, 164, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 70px;
  width: inherit;
}

.btn {
  cursor: pointer;
  background: #007DFF;
  width: 304px;
  height: 70px;
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 16px;
  margin-right: 28px
}

.hidden {
  display: none;
}

.added_integrations {
  margin-top: 30px;
  margin-right: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 146px);
  grid-template-rows: 146px 146px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  > div {
    width: 146px;
    height: 146px;
    margin: 8px;
  }
}

//modal
//modal
.input_section {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  background: #ECF3FD;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.input_error {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  background: #EAC5CF;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
}

.entity {
  display: flex;
  margin-bottom: 2px;
  border-radius: 8px;
  height: 66px;
  align-items: center;
  width: fit-content;
}
.info_wrapper {
  display: flex;
  flex-direction: column;
  float: left;
}