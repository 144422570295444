@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.eventCreate {
  padding: size(40px) size(16px) size(20px) size(40px);
}

.progress {
  margin-bottom: size(30px);
  &__title {
    margin-bottom: size(12px);
    font-size: size(18px);
    color: #435875;
  }
  &__line {
    position: relative;
    width: size(320px);
    height: size(2px);
    background: rgba(0,123,255, .2);
    border-radius: size(2px);
    span {
      position: absolute;
      top: 0;
      left: 0;
      background: #007BFF;
      border-radius: size(2px);
      height: size(2px);
    }
  }
}
.stepFirst {
  width: calc(100% / 2);
}
.stepSecond {
  width: 100%;
}
.stepThird {
  width: 100%;
}

.container {
  display: flex;
  gap: size(44px);
}

.section_copy_block {
  max-width: size(500px);
  padding: size(29px) 0;
}

.form {
  width: size(840px);
  display: flex;
  flex-direction: column;
}
.form_finish {
  width: size(410px);
  display: flex;
  flex-direction: column;
}
.wrapper {
  display: grid;
  //flex-wrap: wrap;
  //justify-content: space-between;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}

.input_section {
  display: flex;
  flex-direction: column;
  position: relative;
}
.input_label {
  display: flex;
  align-items: center;
}

.input {
  margin-top: size(10px);
  margin-bottom: size(22px);
  width: 100%;
  height: size(50px);
  background: #ECF3FD;
  border: none;
  border-radius: size(10px);
  padding-left: size(28px);
  color: #3D5878;
  font-size: size(14px);
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.input_error {
  margin-top: size(10px);
  margin-bottom: size(22px);
  width: size(410px);
  height: size(50px);
  background: #EAC5CF;
  border: none;
  border-radius: size(10px);
  padding-left: size(28px);
  color: #3D5878;
  font-size: size(14px);
  font-weight: 400;
}

.error {
  color: $color-torch-red;
  position: absolute;
  bottom: 0;
  font-size: size(14px);
}

.entity {
  display: flex;
  margin-bottom: size(2px);
  border-radius: size(8px);
  height: size(66px);
  align-items: center;
  width: fit-content;
  //order: 1;
}
.info_wrapper {
  display: flex;
  flex-direction: column;
  float: left;
  label {
    display: flex;
    align-items: center;
    color: #3D5878;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: size(38px);   // 60px;
  height: size(20px);   // 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: size(18px);
  width: size(18px);
  left: size(0.3px);
  bottom: size(1px);
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 size(1px) #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: size(34px);
}

.slider.round:before {
  border-radius: 50%;
}

.btns {
  margin-top: size(40px);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  position: relative;
}

.submit_btn {
  padding: size(16px) size(20px);
  width: size(410px);
  max-height: size(51px);
  border: none;
  border-radius: size(10px);
  background: rgba(0, 123, 255, 1);
  color: #fff;
  margin-bottom: size(20px);
  cursor: pointer;
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
  }
}

.submit_btn_disactive {
  padding: size(16px) size(32px);
  width: size(410px);
  max-height: size(51px);
  border: none;
  border-radius: size(10px);
  background: #F0F2F9;
  color: #627791;
  //margin-top: 40px;
  margin-bottom: size(20px);
  pointer-events: none;
}

.copy_section {
  display: flex;
  flex-direction: column;
  background: #EDF3FC;
  border-radius: size(10px);
  padding: size(22px);
  margin-bottom: size(22px);
}
.sdk_code {
  background: #F9FAFF;
  border: size(2px) solid #EFF0F9;
  border-radius: size(10px);
  padding: size(20px) size(12px);
  margin-bottom: size(12px);
  color: #435875;
  font-size: size(14px);
  height: size(120px);
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.doc {
  height: size(44px);
  background: #F9FAFF;
  border: size(2px) solid #EFF0F9;
  color: #435875;
  border-radius: size(10px);
  cursor: pointer;
  font-size: size(16px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(410px);
  &:hover {
    color: #FFF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
  }
}
.copied {
  width: 100%;
  height: size(44px);
  background: #F9FAFF;
  border: size(2px) solid #EFF0F9;
  color: #435875;
  border-radius: size(10px);
  cursor: pointer;
  font-size: size(16px);
  &:hover {
    color: #FFF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
  }
}
.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: size(44px);
  background: #007DFF;
  color: #FFF;
  border: none;
  border-radius: size(10px);
  cursor: pointer;
  font-size: size(16px);
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
  }
}
.text {
  font-size: size(14px);
  line-height: size(17px);

  color: #8E9EB5;
  margin: 0;
}

.paragraph {
  font-size: size(16px);
  line-height: size(17px);
  color: #8E9EB5;
  margin: size(10px) 0;
  &:nth-of-type(1) {
    margin-top: 0;
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: size(450px);
  height: 100%;
  border-left: size(2px) solid #EFF0F9;
  background: #FDFDFF;
}

.mb {
  margin-bottom: size(12px);
}
.mt {
  margin-bottom: size(12px);

}