@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.info-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: size(24px);
}

.info-modal__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: size(46px);
  height: size(46px);
  margin-bottom: size(16px);

  background-color: $color-alice-blue;
  border-radius: size(10px);
  overflow: hidden;

  svg {
    display: block;
  }
}

.info-modal__title {
  margin-bottom: size(6px);

  font-weight: 500;
  font-size: size(16px);
  line-height: 140%;
  text-align: center;
}

.info-modal__description {
  margin: 0;
  font-weight: 400;
  font-size: size(14px);
  line-height: 140%;
  text-align: center;
}
