@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.confirm-exit {
  &__title {
    color: #435875;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
  }

  &-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
      width: 49%;
      &:last-child {
        background: #E56384;
        &:before {
          background: transparent;
        }
        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #E56384;
        }
      }
    }
  }
}