@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.context {
  .custom-input__rdp {
    animation-name: fade-in-opacity;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
  }

  .custom-input__rdp-inner {
    padding-top: 1px;
    padding-bottom: 1px;

    background-color: $color-default-white;
    border-radius: size(4px);
    box-shadow:
            0 size(1px) size(10px) rgb(0 0 0 / 4%),
            0 size(4px) size(5px) rgb(0 0 0 / 6%),
            0 size(2px) size(4px) size(-1px) rgb(0 0 0 / 9%);
  }

  .custom-select__list {
    position: static;
    bottom: initial;
    z-index: initial;

    background-color: $color-ghost-white;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: size(10px);
    transform: none;
    visibility: visible;
    opacity: 1;
    max-height: size(250px);
  }
}

@keyframes fade-in-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
