.wrapper {
  display: grid;
  grid-template-rows: 0.15fr 1fr 0.6fr;
  min-height: 100vh;
}

.logo {
  width: 155px;
  height: 40px;
  flex-shrink: 0;
  margin-right: auto;
}

.onboard_step {
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: rgba(67, 88, 117, 1);
  margin: 20px 0 12px 0;
}
.onboard_desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #435875;
  padding: 0;
  margin: 0 0 30px 0;
  max-width: 600px;
}

.input_section {
  display: flex;
  flex-direction: column;
}

.input_label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #435875;
  margin-bottom: 10px;
}

.input_label_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8E9EB5;
  max-width: 600px;
}

.input {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  background: #ECF3FD;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  &::placeholder {
    color: #8E9EB5;
  }
}

.btn {
  cursor: pointer;
  background: #007DFF;
  width: 260px;
  height: 70px;
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 16px;
}

.input_error {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  background: #EAC5CF;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
}

.select_wrapper {
  margin-top: 10px;
  margin-bottom: 22px;
  width: 410px;
  height: 50px;
  position: relative;
  border-radius: 10px;
  background: #ECF3FD;
  &:hover {
    box-shadow: 0 0 3px gray;
  }
}

.select_arrow {
  content: '';
  position: absolute;
  top: 20px;
  right: 28px;
  bottom: 17px;
  border: 4px solid transparent;
  border-top-color: #6C7231;
  border-bottom: 0;
}

.select_wrapper select {
  width: 410px;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  padding-right: 28px;
  color: #3D5878;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  appearance: none;
}