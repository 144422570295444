.wrapper {

}

.logo {
  width: 155px;
  height: 40px;
  flex-shrink: 0;
  margin-right: auto;
}

.onboard_step {
  margin: 60px 0 0 0;
  color: rgba(67, 88, 117, 1);
  font-size: 22px;
  line-height: 26.63px;
  font-weight: 400;
}

.section_title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 30px;

  &:first-letter {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 123, 255, 1);
  }
}

.input_section {
  display: flex;
  flex-direction: column;
}

.sdk_code {
  width: 454px;
  height: 200px;
  background: rgba(67, 88, 117, 0.05);
  border-radius: 10px;
  padding: 22px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(67, 88, 117, 1);
  line-height: 15.6px;
  border: none;
}

.inter_btn {
  width: 454px;
  height: 52px;
  background: #ECF3FD;
  color: #007DFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
  &:hover {
    background: #007DFF;
    color: #fff;
  }
}

.description {
  width: 454px;
  color: rgba(142, 158, 181, 1);
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 400;
}

.input {
  width: 454px;
  height: 50px;
  background: rgba(237, 243, 252, 1);
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: rgba(67, 88, 117, 1);
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.input_error {
  width: 454px;
  height: 50px;
  background: #EAC5CF;
  border: none;
  border-radius: 10px;
  padding-left: 28px;
  color: rgba(67, 88, 117, 1);
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.btn {
  cursor: pointer;
  background: #007DFF;
  width: 304px;
  height: 70px;
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 16px;
  margin-right: 28px
}

.check_counter_preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83px;
  width: 454px;
  padding: 32px 22px;
  gap: 10px;
  border-radius: 10px;
  color: #435875;
  margin-top: 22px;
  background: rgba(67, 88, 117, 0.05);
}

.check_counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83px;
  width: 454px;
  padding: 32px 22px;
  gap: 10px;
  border-radius: 10px;
  color: #899FB7;
  border: 2px solid #EFF0FA;
  margin-top: 22px;

  //box-shadow: 0 0 10px rgba(0,0,0,0.5);
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.16);
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83px;
  width: 454px;
  padding: 32px 22px;
  gap: 10px;
  border-radius: 10px;
  color: rgba(67, 88, 117, 1);
  border: 2px solid #EFF0FA;
  margin-top: 22px;
  background: rgba(45, 206, 138, 0.1);
}
.denied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 83px;
  width: 454px;
  padding: 32px 22px;
  gap: 10px;
  border-radius: 10px;
  color: rgba(67, 88, 117, 1);
  margin-top: 22px;
  background: rgba(229, 99, 132, 0.1);
}

.checking {
  width: 454px;
  height: 52px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 12px;
  background: #DCDDDF;
  color: rgba(67, 88, 117, 1);
  font-size: 16px;
  line-height: 20px;
}

.footer {
  margin-top: 32px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}

.skipper {
  border: none;
  background: none;
  cursor: pointer;
  color: rgba(125, 141, 164, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 70px;
  width: inherit;
}

.copied {
  width: 454px;
  height: 52px;
  color: #FFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
  background: rgba(0, 123, 255, 1);
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  width: 450px;
  height: 100%;
  border-left: 2px solid #EFF0F9;
}