@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';
.docs-counter {
  height: 100%;
  &__title {
    width: 100%;
    background: $color-ghost-white;
    box-shadow: 0px -1px 1px 0px $color-ghost-white-2 inset;
    h2 {
      height: size(60px);
      display: flex;
      align-items: center;
      padding: 0 0 0 size(28px);
      margin: 0;
    }
  }
  &__subtitle {
    color: #435875;
    font-weight: 500;
    font-size: size(16px);
    margin-bottom: size(12px);
  }
  &__body {
    overflow-y: auto;
    height: calc(100% - (#{size(60px)}));
    padding: size(28px);
    background: $color-ghost-white;
    h3 {
      font-weight: 400;
      font-size: size(22px);
      line-height: size(27px);

      color: #435875;
      margin: 0 0 size(30px) 0;
      padding: 0;
    }
    button {
      width: 100%;
      margin-bottom: size(12px);
    }
  }
  &__step {
    margin-bottom: size(18px);
  }
  &__head {
    color: #435875;
    font-weight: 500;
    font-size: size(18px);
    line-height: size(22px);
    margin-bottom: size(12px);

    span {
      color: #007BFF;
    }
  }
  &__text {
    font-weight: 400;
    font-size: size(14px);
    line-height: size(18px);
    color: #435875;
    margin: 0 0 size(12px) 0;
    padding: 0;
  }
  &__code {
    font-size: size(12px);
    margin-bottom: size(12px);
  }
  &__table {
    border: 2px solid #EFF0F9;
    border-radius: 10px;
    overflow-x: auto;
    max-width: 100%;

    table {
      width: 100%;
      min-width: 500px;
      //padding: 10px 5px;
      border-collapse: separate;
      border-spacing: 0;
      //table-layout: fixed;

      thead {
        background: #EFF0F9;

        tr th {
          border: none;
          text-align: left;
          padding: 10px 15px;
          font-size: size(14px);
          color: #8E9EB5;
        }
      }

      tbody tr td {
        padding: 10px 15px;
        font-size: size(14px);
        color: #435875;
        align-items: start;
        vertical-align: top;
      }
    }
  }
  &__copy {
    background: rgba(67, 88, 117, 0.05);
    border-radius: size(10px);
    padding: size(22px);
    font-weight: 400;
    font-size: size(12px);
    line-height: size(16px);
    color: #435875;
    margin-bottom: size(12px);
  }
  &__substep {
    margin: size(22px) 0 0 0;
  }
}