@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.modal-settings-role {
  display: flex;
  flex-direction: column;
  gap: size(20px);
  &__input {
    margin: 0;
  }
  &__block {
    display: flex;
    align-items: flex-end;
    gap: size(20px);
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    //justify-content: space-between;
    padding: size(12px);
    border: size(1px) solid rgba(67, 88, 117, 0.12);
    border-radius: size(8px);
    justify-content: flex-start;
    gap: size(20px);
      //padding-bottom: size(4px);
    .reports-create__field_auto {
      margin: 0;
    }
  }
  &__field {
    width: 50%;
  }

  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: size(12px);
  }

  &__checkbox {
    .custom-toggle {
      padding: 0;
    }
  }

  &__actions {
    display: flex;
    gap: size(12px);
    width: 50%;
    button {
      max-width: calc(50% - #{size(6px)});
    }
  }
}