@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  background: rgba(249, 250, 255, 0.6);

  &__spinner {
    display: block;
    width: size(46px);
    height: size(46px);

    border-top: size(4px) solid transparent;
    border-left: size(4px) solid transparent;
    border-right: size(4px) solid $color-dodger-blue;
    border-bottom: size(4px) solid $color-dodger-blue;
    border-radius: size(50px);

    animation-name: spinner;
    animation-timing-function: linear;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
  }

  &.is-not-transparent {
    background: $color-default-white;
  }
  &.is-not-transparent-light {
    background: rgba(255,255,255,.6);
  }
  &.in-block {
    position: absolute;
    z-index: 10;
  }
  &--xs {
    .lds-ring {
      width: size(20px);
      height: size(20px);
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: size(46px);
  height: size(46px);
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: size(4px) solid $color-dodger-blue;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-dodger-blue transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}