@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: size(250px);
}

.logo {
  width: size(155px);
  height: size(40px);
  flex-shrink: 0;
  margin-right: auto;
}

.onboard_step {
  font-weight: 400;
  font-size: size(22px);
  line-height: size(27px);
  color: rgba(67, 88, 117, 1);
  margin: size(20px) 0 size(30px) 0;
}

.input_section {
  display: flex;
  flex-direction: column;
  position: relative;
}
.input_currency {
  position: absolute;
  right: size(15px);
  bottom: size(38px);
  font-size: size(16px);
  color: $color-dodger-blue;
}

.select_wrapper {
  margin-top: size(10px);
  margin-bottom: size(22px);
  width: size(410px);
  height: size(50px);
  position: relative;
  border-radius: size(10px);
  background: #ECF3FD;
  &:hover {
    box-shadow: 0 0 size(3px) gray;
  }
}

.select_arrow {
  content: '';
  position: absolute;
  top: size(20px);
  right: size(28px);
  bottom: size(17px);
  border: size(4px) solid transparent;
  border-top-color: #6C7231;
  border-bottom: 0;
}

.select_wrapper select {
  width: size(410px);
  height: size(50px);
  background: transparent;
  border: none;
  border-radius: size(10px);
  padding-left: size(28px);
  padding-right: size(28px);
  color: #3D5878;
  font-size: size(14px);
  font-weight: 400;
  position: relative;
  display: inline-block;
  appearance: none;
}

.btn {
  cursor: pointer;
  background: #007DFF;
  width: size(260px);
  height: size(70px);
  border: none;
  border-radius: size(10px);
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: size(16px);
  margin-right: size(28px);
}

.footer {
  margin-top: size(50px);
  display: flex;
  align-items: center;
}

.skipper {
  border: none;
  background: none;
  cursor: pointer;
  color: rgba(125, 141, 164, 1);
  font-weight: 500;
  font-size: size(16px);
  line-height: size(20px);
  height: size(70px);
  width: inherit;
}