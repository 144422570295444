@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.user-menu {
  display: grid;
  //grid-template-columns: size(60px) 1fr;
  grid-template-columns: size(160px);

   //max-width: size(220px);

  .btn {
    align-self: center;
    width: 100%;
    color: $color-night-rider;
    &.user-menu {
      &__exit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: size(141px);
        padding: size(12px);

        color: $color-dodger-blue;

        background-color: $color-alice-blue;
        border: none;
        border-radius: size(8px);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);

        appearance: none;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        span {
          font-size: 16px;
        }
        font-size: 16px;

        &:hover {
          background-color: $color-ghost-white;
        }

        svg {
          width: size(14px);
          height: size(14px);
          margin-right: 8px;
        }
      }
    }
  }

  .btn__text {
    flex-shrink: 0;

    font-family: $ff-averta;
    font-weight: 400;
    font-size: size(14px);
    line-height: size(17px);
    text-transform: capitalize;
  }

  &__user-popup {
    background: #F9FAFF;
    position: relative;
    align-self: center;
    border: size(1px) solid rgba(67, 88, 117, 0.08);
    border-radius: size(10px);
    display: flex;
    align-items: center;
    height: size(48px);
    padding: 0 size(10px) 0 0;
    cursor: pointer;
    &:hover {
      background: #EDF3FC;
      span {
        background: #E1EDFF;
      }
    }

    &.is-active {
      .user-menu__user-dropdown-menu {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }

      .btn__triangle {
        transform: rotate(0deg);
      }
    }
    span {
      height: size(46px);
      width: size(46px);
      background: #EDF3FC;
      border-radius: size(10px) 0 0 size(10px);
      margin-right: size(12px);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: size(14px);
        margin: 0;
        padding: 0;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #435875;
    }
  }

  &__user-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 50%;
    z-index: 5;

    opacity: 0;

    pointer-events: none;
    visibility: hidden;
    transform: translateX(50%);
    transition: visibility $trans-default, opacity $trans-default;

    background: #F9FAFF;
    border: 1px solid #EFF0F9;
    border-radius: 10px;
    overflow: hidden;
  }

  &__exit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(141px);
    padding: size(12px);

    color: $color-dodger-blue;

    background-color: $color-alice-blue;
    border: none;
    border-radius: size(8px);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);

    appearance: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: $color-ghost-white;
    }

    svg {
      width: size(14px);
      height: size(14px);
      margin-right: 8px;
    }
  }
}
