@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.wrapper {
  display: flex;
  height: 100%;
  padding: size(40px) size(16px) size(20px) size(40px);
}
.title {
  font-size: size(18px);
  color: #435875;
  margin-top: 0;
  margin-bottom: size(30px);
}

.form {
  width: 100%;
  margin-right: size(12px);
}

.integratons_hidden {
  width: 100%;
}

.integrations {
  width: 50%;
  border-width: 0 0 0 size(2px);
  border-color: #EFF0FA;
  border-style: solid;
  margin-top: size(-41px);
  height: calc(100% + #{size(60px)});
}

.onboard_step {
  margin: size(60px) 0 0 0;
  color: rgba(67, 88, 117, 1);
  font-size: size(22px);
  line-height: size(26.63px);
  font-weight: 400;
}

.add_btn {
  cursor: pointer;
  border: none;
  border-radius: size(10px);
  width: size(146px);
  height: size(146px);
  background: #ECF3FD;
  color: #007CFF;
  font-size: size(14px);
  margin-top: size(8px);
  margin-left: size(8px);
  &:before {
    content: '+';
    font-size: size(32px);
  }
}

.modal_title {
  border-width: 0 0 size(2px) 0;
  border-color: #EFF0FA;
  border-style: solid;
  margin: 0;
  padding: size(20px) 0 size(20px) size(28px);
  color: rgba(125, 141, 164, 1);
  line-height: size(19px);
  text-transform: uppercase;
}

.options {
  margin: size(22px) size(4px) size(10px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

figure {
  margin: 0;
}

figcaption {
  text-align: center;
}
.search_block {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.search_input {
  width: 100%;
  //margin: 0 auto;
  margin-left: size(7px);
  //height: 42px;
  border: none;
  //border-radius: 10px;
  //background: #EAF3FD;
  //color: #899FB7;
  //padding: 12px 28px;
}

.nails {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-around;  // for big screens
  color: #607892;
  margin-top: size(10px);
  white-space:nowrap;
  gap: size(8px);
  margin-left: size(4px);
  padding: 0 size(2px);
  width: 100%;
}

.nail {
  display: inline-block;
  background: #EAF3FD;
  border-radius: size(10px);
  padding: size(8px) size(12px);
  color: rgba(67, 88, 117, 0.8);
  font-size: size(13px);
  line-height: size(16px);
  font-weight: 400;
  cursor: pointer;
  border: size(1px) solid transparent;
}
.active {
    color: #007CFF;
    border: size(1px) solid #007CFF;
}

.integration_card {
  max-width: size(146px);
  height: size(146px);
  background: rgba(237, 243, 252, 1);
  border-radius: size(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: size(14px);
  margin: size(8px);
}

@mixin grid($countColumns, $margin) {
  margin-right: $margin;
  margin-bottom: $margin;
  height: size(146px);
  width: ((100% - (($countColumns - 1 ) * $margin)) / $countColumns);

  &:nth-child(#{ $countColumns }n){
    margin-right: 0;
  }

}

.grid {
  display: flex;
  flex-wrap: wrap;
  > div {
    float: left;
    @include grid(3, 0.1%);
    //flex: 1 1 146px;
  }
}

figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//.card_img {
//  margin-bottom: 8px;
//}

img {
  margin-bottom: size(8px);
}

.footer {
  margin-top: size(32px);
  display: flex;
  align-items: center;
}

.skipper {
  border: none;
  background: none;
  cursor: pointer;
  color: rgba(125, 141, 164, 1);
  font-weight: 500;
  font-size: size(16px);
  line-height: size(20px);
  height: size(70px);
  width: inherit;
}

.btn {
  cursor: pointer;
  background: #007DFF;
  width: size(304px);
  height: size(70px);
  border: none;
  border-radius: size(10px);
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: size(16px);
  margin-right: size(28px)
}

.hidden {
  display: none;
}

.added_integrations {
  margin-top: size(30px);
  margin-right: size(30px);
  display: grid;
  grid-template-columns: repeat(auto-fill, size(146px));
  grid-template-rows: size(146px) size(146px);
  grid-column-gap: size(20px);
  grid-row-gap: size(20px);
  justify-items: stretch;
  align-items: stretch;
  > div {
    width: size(146px);
    height: size(146px);
    margin: size(8px);
  }
}




//modal
.input_section {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: size(10px);
  margin-bottom: size(22px);
  width: 100%;
  height: size(50px);
  background: #ECF3FD;
  border: none;
  border-radius: size(10px);
  padding-left: size(28px);
  color: #3D5878;
  font-size: size(14px);
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.input_error {
  margin-top: size(10px);
  margin-bottom: size(22px);
  width: size(410px);
  height: size(50px);
  background: #EAC5CF;
  border: none;
  border-radius: size(10px);
  padding-left: size(28px);
  color: #3D5878;
  font-size: size(14px);
  font-weight: 400;
}

.entity {
  display: flex;
  margin-bottom: size(2px);
  border-radius: size(8px);
  height: size(66px);
  align-items: center;
  width: fit-content;
}
.info_wrapper {
  display: flex;
  flex-direction: column;
  float: left;
}